import React, { useState } from "react"

import UsbIcon from "@mui/icons-material/Usb"
import { Box, Button, Dialog, DialogActions, DialogTitle, Grid } from "@mui/material"
import type { AxiosError } from "axios"
import { useSnackbar } from "notistack"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { useSearchParams } from "react-router-dom"

import api from "api/api"
import logoOkta from "assets/images/okta_logo.png"
import { COLORS } from "assets/styles/colors"
import Authenticator from "components/authentication/Authenticator"
import { AbsoluteLoader } from "components/loader"
import Logo from "components/logo"
import { Title } from "components/typography/Title"
import Version from "components/version/Version"
import { getConfiguration } from "config/env"
import AuthenticationType from "screens/authentication/AuthenticationType"
import actions from "store/actions"
import { LoginForm } from "types/api.types"
import { CONFIG, ERRORS, RECAPTCHA_ACTIONS } from "types/enums.types"
import { AUTH_MODE, AUTH_TYPE, LOCAL_STORAGE } from "utils/authentication-constants.utils"
import { getErrorMessages } from "utils/error.utils"

import Form from "./Authentication.form"
import * as S from "./Authentication.styles"

const Authentication: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const intl = useIntl()

  const d = useDispatch()

  const authenticateUser = (token: string, mode: AUTH_MODE) => {
    localStorage.setItem(LOCAL_STORAGE.API_TOKEN, token)
    localStorage.setItem(LOCAL_STORAGE.AUTH_MODE, mode)
    d(actions.auth.login({ token, mode }))
  }

  const authMode = localStorage.getItem(LOCAL_STORAGE.AUTH_MODE)
  const authToken = localStorage.getItem(LOCAL_STORAGE.API_TOKEN)
  const [searchParams] = useSearchParams()

  const code = searchParams.get("code")

  const [selectedType, setSelectedType] = useState<AUTH_TYPE | undefined>()
  const [selectedMode, setSelectedMode] = useState<AUTH_MODE | undefined>()
  const [isModal, setIsModal] = useState(false)

  const handlePressLogin = (authMode: AUTH_MODE, authType?: AUTH_TYPE) => {
    setSelectedMode(authMode)
    setSelectedType(authType)
  }

  const { executeRecaptcha } = useGoogleReCaptcha()

  const onSubmitForm = async (values: LoginForm) => {
    if (!executeRecaptcha) {
      enqueueSnackbar(intl.formatMessage({ id: "error.recaptcha" }), { variant: "error" })
      return
    }

    const recaptchaToken = await executeRecaptcha(RECAPTCHA_ACTIONS.LOGIN)

    try {
      setLoading(true)
      const { data } = await api.login.portal({ ...values, recaptchaToken })
      const { token } = data

      authenticateUser(token, AUTH_MODE.RESTIT)
    } catch (e) {
      const messages = getErrorMessages(e as AxiosError)
      messages.forEach((m) => {
        m === `ERROR.${ERRORS.MUST_CHANGE_PASSWORD}`
          ? setIsModal(true)
          : enqueueSnackbar(intl.formatMessage({ id: m }), { variant: "error" })
      })
    } finally {
      setLoading(false)
    }
  }

  const ENABLE_IDP = getConfiguration(CONFIG.ENABLE_IDP) === "true"

  if (selectedMode || (authMode === AUTH_MODE.IDP && authToken) || code) {
    return <Authenticator authMode={selectedMode || (authMode as AUTH_MODE)} authType={selectedType} />
  }

  return (
    <Grid minHeight="100vh" display="flex" flexDirection="column" alignItems="center">
      {loading && <AbsoluteLoader />}
      <Grid maxWidth={1440} p={2} width="100%">
        <Box p={2} pl={4} pr={4} width="100%">
          <Box display="flex" alignItems="center">
            <Logo />
          </Box>
        </Box>
        <Box p={2} pb={0} width="100%" display="flex" justifyContent="center">
          <Title>
            <FormattedMessage id="screen.authentication.title" />
          </Title>
        </Box>

        <Grid xs={12} item container p={2} flexDirection="row">
          <Grid xs={6} item container p={2}>
            <Grid xs={12} p={4} pt={2} pb={2} item container sx={{ backgroundColor: COLORS.GREY_BG }} justifyContent="space-between">
              {ENABLE_IDP && (
                <AuthenticationType
                  onPress={() => handlePressLogin(AUTH_MODE.IDP, AUTH_TYPE.RNET)}
                  titleKey={"screen.authentication.usb"}
                  buttonKey={"screen.authentication.usb.submit"}
                  icon={() => <UsbIcon fontSize="inherit" />}
                  twoAuthMode={ENABLE_IDP}
                />
              )}

              <AuthenticationType
                onPress={() => handlePressLogin(AUTH_MODE.OKTA)}
                titleKey={"screen.authentication.okta"}
                buttonKey={"screen.authentication.okta.submit"}
                icon={() => <img src={logoOkta} width={140} />}
                twoAuthMode={ENABLE_IDP}
              />
            </Grid>
          </Grid>
          <Grid xs={6} item container p={2}>
            <Grid xs={12} p={4} pt={2} pb={2} item container sx={{ backgroundColor: COLORS.GREY_BG }}>
              <Grid item container xs={12} p={2} justifyContent="center">
                <S.BlockTitle>
                  <FormattedMessage id="screen.authentication.login" />
                </S.BlockTitle>
              </Grid>
              <Form onSubmit={onSubmitForm} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item container xs={12} p={2} justifyContent="flex-end">
          <Version />
        </Grid>
      </Grid>

      <Dialog onClose={() => setIsModal(false)} open={isModal}>
        <Box p={2}>
          <DialogTitle>
            <FormattedMessage id={`ERROR.${ERRORS.MUST_CHANGE_PASSWORD}`} />
          </DialogTitle>
          <DialogActions>
            <Button variant="outlined" onClick={() => setIsModal(false)}>
              <FormattedMessage id="button.ok" />
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </Grid>
  )
}

export default Authentication
