import styled from "styled-components"

/* == custom styles == */
export const PageContainer = styled.main`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-around;
  gap: 10px;
  padding: 60px 30px;
  min-height: 100vh;
  box-sizing: border-box;
`

export const RenaultLogo = styled.img`
  height: 100%;
  object-fit: contain;
  max-height: 80px;
`

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 300px;
  align-self: center;
  width: 70%;
  gap: 10px;
`

export const Image = styled.img`
  cursor: pointer;
  align-self: center;
  width: 100%;
`

export const Title = styled.h2`
  text-align: center;
`
