import React, { useEffect, useState } from "react"

import { DateTimePicker } from "@mui/lab"
import type { TextFieldProps } from "@mui/material"
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, Switch, TextField } from "@mui/material"
import { useFormik } from "formik"
import { FormattedMessage, useIntl } from "react-intl"
import { date, object, string } from "yup"

import { Title } from "components/typography/Title"
import { IdName, PrepareAgendaEvent } from "types/common.types"
import { CreateEventForm } from "types/form.types"

import * as S from "./Agenda.styles"
import { SwitchText } from "./Agenda.styles"

enum TextFields {
  signatoryFirstName = "signatoryFirstName",
  signatoryLastName = "signatoryLastName",
  signatoryEmail = "signatoryEmail",
  signatoryPhone = "signatoryPhone",
}

const signatoryTextFields = [
  TextFields.signatoryFirstName,
  TextFields.signatoryLastName,
  TextFields.signatoryEmail,
  TextFields.signatoryPhone,
]

type Props = {
  onSubmit: (values: CreateEventForm) => unknown
  vehicle: PrepareAgendaEvent
}

const Form = ({ onSubmit, vehicle }: Props) => {
  const intl = useIntl()
  const { date: vDate, editable } = vehicle

  const formik = useFormik({
    validationSchema: object().shape({
      date: date()
        .typeError(intl.formatMessage({ id: "form.error.dateTimeFormat" }))
        .nullable()
        .required(intl.formatMessage({ id: "form.error.required" })),
      officeId: string().required(intl.formatMessage({ id: "form.error.required" })),
      userId: string().required(intl.formatMessage({ id: "form.error.required" })),
    }),
    initialValues: {
      date: vehicle.date ? new Date(vehicle.date + "Z") : new Date(),
      comment: vehicle.comment ?? "",
      officeId: vehicle?.receptionPlaceRrf ? vehicle?.receptionPlaceRrf : vehicle?.offices?.[0]?.id,
      userId: vehicle.receptionist ? vehicle.receptionist.id : "",
      signatoryFirstName: vehicle?.signatory?.firstName ?? "",
      signatoryLastName: vehicle?.signatory?.lastName ?? "",
      signatoryEmail: vehicle?.signatory?.email ?? "",
      signatoryPhone: vehicle?.signatory?.phone ?? "",
      noSignatory: vehicle.noSignatory,
    },
    onSubmit: (values: CreateEventForm) => {
      const response = Object.fromEntries(Object.entries(values).filter(([, v]) => v !== ""))
      onSubmit && onSubmit(response as CreateEventForm)
    },
  })

  const { touched, values, errors } = formik

  const [users, setUsers] = useState<IdName<string, string>[] | undefined>()

  useEffect(() => {
    const users = vehicle?.offices?.find((o) => formik.values.officeId === o.id)?.users

    setUsers(users)
    formik.setFieldValue("userId", users?.[0]?.id ?? "")
  }, [formik.values.officeId])

  useEffect(() => {
    formik.setFieldValue("date", vehicle.date ? new Date(vehicle.date + "Z") : new Date())
    formik.setFieldValue("officeId", vehicle.receptionPlaceRrf ? vehicle.receptionPlaceRrf : vehicle?.offices?.[0].id)
    formik.setFieldValue("comment", vehicle.comment ? vehicle.comment : "")
    formik.setFieldValue(TextFields.signatoryFirstName, vehicle.signatory?.firstName ? vehicle.signatory.firstName : "")
    formik.setFieldValue(TextFields.signatoryLastName, vehicle.signatory?.lastName ? vehicle.signatory.lastName : "")
    formik.setFieldValue(TextFields.signatoryEmail, vehicle.signatory?.email ? vehicle.signatory.email : "")
    formik.setFieldValue(TextFields.signatoryPhone, vehicle.signatory?.phone ? vehicle.signatory.phone : "")
    formik.setFieldValue("noSignatory", vehicle.noSignatory)
  }, [vehicle])

  return (
    <Grid item container xs={12}>
      <S.Form onSubmit={formik.handleSubmit}>
        <Grid item container xs={12}>
          <Grid item container xs={12} pt={1}>
            <Grid item container xs={12} pl={1}>
              <Title>
                <FormattedMessage id="form.agenda.event.title" />
              </Title>
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs={12} p={1}>
                <DateTimePicker
                  label={intl.formatMessage({
                    id: "form.agenda.date",
                  })}
                  disabled={!editable}
                  value={values.date}
                  InputAdornmentProps={{ position: "start" }}
                  onChange={(date: "" | Date | null) => formik.setFieldValue("date", date)}
                  minDateTime={new Date()}
                  renderInput={(params: TextFieldProps) => (
                    <TextField
                      {...params}
                      id="date"
                      name="date"
                      size="small"
                      sx={{ backgroundColor: "white" }}
                      fullWidth
                      onBlur={formik.handleBlur}
                      helperText={touched.date && errors.date}
                      error={touched.date && Boolean(errors.date)}
                    />
                  )}
                />
              </Grid>
              <Grid item container xs={12} p={1}>
                <TextField
                  fullWidth
                  size="small"
                  disabled={editable ? !editable : new Date(vDate + "Z") < new Date()}
                  id="comment"
                  name="comment"
                  label={intl.formatMessage({
                    id: "form.agenda.comment",
                  })}
                  sx={{ backgroundColor: "white" }}
                  value={values.comment}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={touched.comment && Boolean(errors.comment)}
                  helperText={touched.comment && errors.comment}
                />
              </Grid>
              <Grid item container xs={12} p={1}>
                <FormControl fullWidth>
                  <InputLabel id="officeId-label">
                    <FormattedMessage id="form.agenda.office" />
                  </InputLabel>
                  <Select
                    fullWidth
                    disabled={!editable}
                    size="small"
                    id="officeId"
                    name="officeId"
                    labelId="officeId-label"
                    label={intl.formatMessage({
                      id: "form.agenda.office",
                    })}
                    sx={{ backgroundColor: "white" }}
                    value={values.officeId}
                    onChange={(event) => {
                      formik.setFieldValue("userId", "")
                      formik.handleChange(event)
                    }}
                    onBlur={formik.handleBlur}
                    error={touched.officeId && Boolean(errors.officeId)}
                  >
                    {vehicle?.offices?.map((office) => (
                      <MenuItem value={office.id} key={office.id}>
                        {office.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item container xs={12} p={1}>
                <FormControl fullWidth>
                  <InputLabel id="userId-label">
                    <FormattedMessage id="form.agenda.receptionist" />
                  </InputLabel>
                  <Select
                    fullWidth
                    size="small"
                    id="userId"
                    name="userId"
                    labelId="userId-label"
                    label={intl.formatMessage({
                      id: "form.agenda.receptionist",
                    })}
                    sx={{ backgroundColor: "white" }}
                    value={values.userId}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={touched.userId && Boolean(errors.userId)}
                    disabled={!users || users.length === 0 || !editable}
                  >
                    {users &&
                      users.map((user) => (
                        <MenuItem value={user.id} key={user.id}>
                          {user.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Grid item container xs={12} pl={1}>
              <Title>
                <FormattedMessage id="form.agenda.signatory.title" />
              </Title>
            </Grid>
            <Grid item container xs={12}>
              {signatoryTextFields?.map((f) => (
                <Grid key={f} item xs={6} p={1}>
                  <TextField
                    fullWidth
                    size="small"
                    id={f}
                    name={f}
                    label={intl.formatMessage({
                      id: `form.agenda.${f}`,
                    })}
                    disabled={values.noSignatory || !editable}
                    sx={{ backgroundColor: "white" }}
                    value={values[f]}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={touched[f] && Boolean(errors[f])}
                    helperText={touched[f] && errors[f]}
                  />
                </Grid>
              ))}
              <Grid item xs={12} p={1} justifyContent="flex-end" alignItems="flex-end">
                <Switch
                  id="noSignatory"
                  disabled={!editable}
                  name="noSignatory"
                  checked={values.noSignatory}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <SwitchText> {intl.formatMessage({ id: "form.agenda.noSignatory" })} </SwitchText>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container xs={12} justifyContent="flex-end" p={1}>
            <Button type="submit" variant="contained" disabled={!formik.isValid}>
              <FormattedMessage id="form.agenda.button.submit" />
            </Button>
          </Grid>
        </Grid>
      </S.Form>
    </Grid>
  )
}

export default Form
