import { IntlShape } from "react-intl/src/types";

import type { BalanceContract, BalanceTenant, BalanceVehicle } from "types/common.types";
import { BalanceResult } from "types/common.types";

import { formatDate, formatKmExceededPayment, formatMileage, formatNbMonths, formatPrice } from "./search.utils";

export const calculItems: { key: keyof BalanceResult, format?: (intl: IntlShape, value?: unknown) => string }[] = [
  {
    key: "mileage",
    format: formatMileage
  },
  {
    key: "restitutionDate",
    format: formatDate,
  },
]

export const contractItems: { key: keyof BalanceContract, format?: (intl: IntlShape, value?: unknown) => string }[] = [
  {
    key: "contractType",
  },
  {
    key: "contractNumber",
  },
  {
    key: "startDate",
    format: formatDate,
  },
  {
    key: "kmContracted",
    format: formatMileage,
  },
  {
    key: "endDate",
    format: formatDate,
  },
  {
    key: "kmExceededPayment",
    format: formatKmExceededPayment,
  },
  {
    key: "duration",
    format: formatNbMonths,
  },
  {
    key: "receptionPlace",
  },
]

export const vehicleItems: { key: keyof BalanceVehicle, format?: (intl: IntlShape, value?: unknown) => string }[] = [
  {
    key: "brand",
  },
  {
    key: "firstRegistrationDate",
    format: formatDate
  },
  {
    key: "model",
  },
  {
    key: "type",
  },
  {
    key: "licensePlate",
  },
  {
    key: "salePrice",
    format: formatPrice,
  },
  {
    key: "chassis",
  },
]

export const tenantItems: { key: keyof BalanceTenant, format?: (intl: IntlShape, value?: unknown) => string }[] = [
  {
    key: "lastName",
  },
  {
    key: "address",
  },
]
