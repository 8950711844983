import React from "react"

import { DatePicker } from "@mui/lab"
import type { TextFieldProps } from "@mui/material"
import { Box, Button, Grid, TextField } from "@mui/material"
import { useFormik } from "formik"
import { FormattedMessage, useIntl } from "react-intl"
import { date, number, object, string } from "yup"

import { Title } from "components/typography/Title"
import type { BalanceForm } from "types/form.types"

import * as S from "./Balance.styles"

type Props = {
  onSubmit: (values: BalanceForm) => Promise<void>
}

const Form = ({ onSubmit }: Props) => {
  const intl = useIntl()

  const formik = useFormik({
    validationSchema: object().shape({
      startDate: date()
        .typeError(intl.formatMessage({ id: "form.error.dateTimeFormat" }))
        .nullable()
        .required(intl.formatMessage({ id: "form.error.required" })),
      mileage: number().required(intl.formatMessage({ id: "form.error.required" })),
      contractNumber: string(),
      licensePlate: string(),
    }).test(
      "contract-or-plate",
      intl.formatMessage({ id: "form.error.contractOrPlateRequired" }),
      function (value) {
        const { contractNumber, licensePlate } = value;
        if (!contractNumber && !licensePlate) {
          return this.createError({
            path: "contractNumber", // Associate the error with one of the fields
            message: intl.formatMessage({ id: "form.error.contractOrPlateRequired" }),
          });
        }
        return true;
      }
    ),
    initialValues: {
      startDate: new Date,
      mileage: 0,
      contractNumber: "",
      licensePlate: "",
    },
    onSubmit: (values: BalanceForm) => {
      const response = Object.fromEntries(Object.entries(values).filter(([, v]) => v !== ""))
      onSubmit && onSubmit(response as BalanceForm)
    },
  })

  const handleResetForm = () => {
    resetForm()
  }

  const { touched, values, errors, resetForm } = formik

  return (
    <Grid item container xs={12}>
      <S.Form onSubmit={formik.handleSubmit}>
        <Grid item container xs={12}>
          <Grid item container xs={12} pt={1}>
            <Grid item container xs={12} pl={1}>
              <Title>
                <FormattedMessage id="form.balance.title" />
              </Title>
            </Grid>
            <Grid item container xs={12} pt={1}>

              <S.Subtitle>
                <FormattedMessage id="form.balance.contract" />
              </S.Subtitle>

              <Grid item container xs={12} p={1}>
                <TextField
                  fullWidth
                  size="small"
                  id="contractNumber"
                  name="contractNumber"
                  label={intl.formatMessage({
                    id: "form.balance.contractNumber",
                  })}
                  sx={{ backgroundColor: "white" }}
                  value={values.contractNumber}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={formik.values.licensePlate !== ""}
                  error={touched.contractNumber && Boolean(errors.contractNumber)}
                  helperText={touched.contractNumber && errors.contractNumber}
                />
              </Grid>

              <Box p={0} pl={2}>
                <FormattedMessage id="form.balance.or" />
              </Box>

              <Grid item container xs={12} p={1}>
                <TextField
                  fullWidth
                  size="small"
                  id="licensePlate"
                  name="licensePlate"
                  label={intl.formatMessage({
                    id: "form.balance.licensePlate",
                  })}
                  sx={{ backgroundColor: "white" }}
                  value={values.licensePlate}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={formik.values.contractNumber !== ""}
                  error={touched.licensePlate && Boolean(errors.licensePlate)}
                  helperText={touched.licensePlate && errors.licensePlate}
                />
              </Grid>

              <S.Subtitle>
                <FormattedMessage id="form.balance.calcul" />
              </S.Subtitle>

              <Grid item xs={12} p={1} pt={2}>
                <DatePicker
                  label={intl.formatMessage({
                    id: "form.balance.startDate",
                  })}
                  value={values.startDate}
                  InputAdornmentProps={{ position: "start" }}
                  onChange={(date: "" | Date | null) => formik.setFieldValue("startDate", date)}
                  renderInput={(params: TextFieldProps) => (
                    <TextField
                      {...params}
                      id="startDate"
                      name="startDate"
                      size="small"
                      sx={{ backgroundColor: "white" }}
                      fullWidth
                      onBlur={formik.handleBlur}
                      helperText={touched.startDate && errors.startDate}
                      error={touched.startDate && Boolean(errors.startDate)}
                    />
                  )}
                />
              </Grid>
              <Grid item container xs={12} p={1}>
                <TextField
                  fullWidth
                  size="small"
                  id="mileage"
                  name="mileage"
                  label={intl.formatMessage({
                    id: "form.balance.mileage",
                  })}
                  sx={{ backgroundColor: "white" }}
                  value={values.mileage}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={touched.mileage && Boolean(errors.mileage)}
                  helperText={touched.mileage && errors.mileage}
                />
              </Grid>

            </Grid>
          </Grid>
          <Grid item container xs={12} justifyContent="flex-end" p={1}>
            <Button variant="contained" color="secondary" onClick={handleResetForm} sx={{ mr: 1 }}>
              <FormattedMessage id="form.balance.button.reset" />
            </Button>
            <Button type="submit" variant="contained" disabled={!formik.isValid}>
              <FormattedMessage id="form.balance.button.submit" />
            </Button>
          </Grid>
        </Grid>
      </S.Form>
    </Grid>
  )
}

export default Form
