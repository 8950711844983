import React, { useEffect, useState } from "react"

import { CancelRounded, CheckCircle } from "@mui/icons-material"
import { Box, CircularProgress } from "@mui/material"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import { FormattedMessage } from "react-intl"
import { useSearchParams } from "react-router-dom"

import api from "api/api"
import Logo from "components/logo"
import { Title } from "components/typography/Title"
import { RECAPTCHA_ACTIONS } from "types/enums.types"

const Validation = () => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const { executeRecaptcha } = useGoogleReCaptcha()
  const [searchParams] = useSearchParams()
  const key = searchParams.get("key") || ""

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = async () => {
    if (!executeRecaptcha) {
      console.error("Execute recaptcha not yet available")
      return
    }

    const recaptchaToken = await executeRecaptcha(RECAPTCHA_ACTIONS.VALIDATION)
    await api.validate({ key, recaptchaToken })
  }

  // You can use useEffect to trigger the verification as soon as the component being loaded
  useEffect(() => {
    if (executeRecaptcha) {
      load()
    }
  }, [executeRecaptcha])

  const load = async () => {
    try {
      await handleReCaptchaVerify()
    } catch (e) {
      setError(true)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Box minHeight="100vh" display="flex" flexDirection="column" alignItems="center">
      <Box maxWidth={1440} p={2} width="100%">
        <Logo />
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" flex={1}>
        {loading && <CircularProgress size={42} />}
        {!loading && !error && (
          <>
            <Box p="0 4px" display="flex" alignItems="center">
              <CheckCircle fontSize={"large"} color="success" />
            </Box>
            <Title>
              <FormattedMessage id="screen.signUp.success" />
            </Title>
          </>
        )}
        {!loading && error && (
          <>
            <Box p="0 4px" display="flex" alignItems="center">
              <CancelRounded fontSize={"large"} color="error" />
            </Box>
            <Title>
              <FormattedMessage id="screen.signUp.error" />
            </Title>
          </>
        )}
      </Box>
    </Box>
  )
}

export default Validation
