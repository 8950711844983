const forms = {
  "form.authentication.login": "Email",
  "form.authentication.password": "Mot de passe",
  "form.authentication.button.submit": "Connexion avec identifiants",
  "form.agenda.event.title": "Événement",
  "form.agenda.signatory.title": "Signataire",
  "form.agenda.date": "Date",
  "form.agenda.comment": "Commentaire",
  "form.agenda.receptionist": "Réceptionnaire",
  "form.agenda.noUser": "Non connu",
  "form.agenda.office": "Lieu de la restitution",
  "form.agenda.signatoryFirstName": "Prénom",
  "form.agenda.signatoryLastName": "Nom",
  "form.agenda.signatoryEmail": "E-mail",
  "form.agenda.signatoryPhone": "Téléphone",
  "form.agenda.noSignatory": "Aucun signataire ne sera présent",
  "form.agenda.button.submit": "Créer le rendez-vous",

  // Balance
  "form.balance.title": "Calcul du solde",
  "form.balance.mileage": "Kilométrage à date de la restitution",
  "form.balance.startDate": "Date de restitution",
  "form.balance.contractNumber": "Numéro de contrat",
  "form.balance.licensePlate": "Immatriculation du véhicule",
  "form.balance.button.submit": "Calculer le solde",
  "form.balance.button.reset": "Effacer",
  "form.balance.contract": "Rechercher un contrat",
  "form.balance.calcul": "Paramètres de calcul",
  "form.balance.or": "OU",

  //Errors
  "form.error.required": "Ce champ est requis",
  "form.error.dateTimeFormat": "La date n'est pas valide",
  "form.error.contractOrPlateRequired": "Un numéro de contrat ou une immatriculation est requis",

}

export default forms
