import React, { useEffect, useState } from "react"

import { Box, Pagination, Stack } from "@mui/material"
import { AxiosError } from "axios"
import { useSnackbar } from "notistack"
import qs from "qs"
import { FormattedMessage, useIntl } from "react-intl"
import { useNavigate, useSearchParams } from "react-router-dom"

import api from "api/api"
import ToggleFilter from "components/filter/ToggleFilter"
import HeaderSearch from "components/headerSearch"
import { AbsoluteLoader } from "components/loader"
import { Title } from "components/typography/Title"
import { useReduxDispatch, useReduxSelector } from "store"
import actions from "store/actions"
import { FILTER_TYPES, FILTERS } from "types/enums.types"
import { getErrorMessages } from "utils/error.utils"
import { searchFiltersToQueryParams, searchParamToObject } from "utils/search.utils"

import SearchTable from "./SearchTable"

const queryParamsBlacklist = ["allowCancelled"]

function Search() {
  const [loading, setLoading] = useState<boolean>(true)
  const { enqueueSnackbar } = useSnackbar()
  const intl = useIntl()
  const dispatch = useReduxDispatch()
  const navigate = useNavigate()

  const data = useReduxSelector((state) => state.search)
  const { results, refresh, filters, q, nbResults, size, page } = data
  const [searchParams] = useSearchParams()
  const parsed = searchParamToObject(searchParams)

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    dispatch(actions.search.page(value))
    window.scrollTo(0, 0)
  }

  const updateSearchUrl = (params: Record<string, unknown>) => {
    const query = Object.keys(params)
      .filter((k) => !queryParamsBlacklist.includes(k))
      .reduce(
        (prev, cur) => ({
          ...prev,
          [cur]: params[cur],
        }),
        {}
      )
    const stringified = qs.stringify(query, { indices: false })

    navigate(`${location.pathname}?${stringified}`, { replace: true })
  }

  const search = async (params: Record<string, unknown>) => {
    try {
      setLoading(true)

      params.allowCancelled = true
      if (params.q === "") {
        delete params.q
      }
      updateSearchUrl(params)

      const { data } = await api.search.restitution(params)
      dispatch(actions.search.search(data))
    } catch (e) {
      dispatch(actions.search.resetRefresh())

      const messages = getErrorMessages(e as AxiosError)
      messages.forEach((m) => enqueueSnackbar(intl.formatMessage({ id: m }), { variant: "error" }))
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const params = searchFiltersToQueryParams(filters)

    params.size = searchParams.get("size") || size
    params.page = searchParams.get("page") || page
    params.q = searchParams.get("q") || q

    search({ ...parsed, ...params })
  }, [])

  // Get data every time refresh is true
  useEffect(() => {
    if (refresh) {
      const params = searchFiltersToQueryParams(filters)

      params.q = q
      params.size = size
      params.page = page

      search(params)
    }
  }, [refresh])

  const contactTypeFilter = filters[FILTERS.CONTRACT_TYPE]

  return (
    <Box p={2} width="100%">
      {loading && <AbsoluteLoader />}
      <HeaderSearch onChangeText={(t: string) => dispatch(actions.search.q(t))} initialText={searchParams.get("q") || q} />
      <Box pt={2} pb={2}>
        <Title>{nbResults > -1 && <FormattedMessage id="search.results" values={{ number: nbResults }} />}</Title>
      </Box>
      <Box>
        {contactTypeFilter && contactTypeFilter.type === FILTER_TYPES.TERM && (
          <ToggleFilter filter={contactTypeFilter} name={FILTERS.CONTRACT_TYPE} />
        )}
      </Box>
      <SearchTable results={results} nbResults={nbResults} />
      <Box display="flex" justifyContent="center" position="relative" p={2}>
        <Stack spacing={2}>
          {!!nbResults &&
            <Pagination onChange={handleChange} count={Math.ceil(nbResults / size)} shape="rounded" page={page} />}
        </Stack>
      </Box>
    </Box>
  )
}

export default Search
