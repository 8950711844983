import * as React from "react"

import { Box } from "@mui/material"
import styled from "styled-components"

import Header from "components/header"

const Container = styled.main`
  display: flex;
  flex: 1;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
`

interface Props {
  children: React.ReactNode
}

const headerHeight = 50

const DefaultLayout: React.FC<Props> = ({ children }) => (
  <Container>
    <Header height={headerHeight} />
    <Box width="100%" maxWidth={1650} mt={`${headerHeight}px`}>
      {children}
    </Box>
  </Container>
)

export default DefaultLayout
