import { Configuration } from "types/common.types"
import { CONFIG, CONFIGURATION_FILE, WINDOW_PROPERTIES } from "types/enums.types"

declare global {
  interface Window {
    CONFIGURATION: Configuration
  }
}

window[WINDOW_PROPERTIES.CONFIGURATION] = {
  CLIENT_ID_ARCA: "",
  REDIRECT_URI: "",
  AUTHORIZATION_ENDPOINT: "",
  TOKEN_ENDPOINT: "",
  REQUESTED_SCOPES_ARCA: "",
  ACR_VALUES_ARCA: "",
  API_BASE_HOSTNAME: "",
  API_PORTAL_BASE_URL: "",
  API_PUBLIC_BASE_URL: "",
  API_GATEWAY_KEY_PORTAL: "",
  API_GATEWAY_KEY_PUBLIC: "",
  RECAPTCHA_KEY: "",
  DEKRA_PORTAL_URL: "",
  DEV_TOKEN: "",
  ENV: "",
  TOKEN_ENDPOINT_OKTA: "",
  ACR_VALUES_RNET: "",
  CLIENT_ID_OKTA: "",
  CLIENT_ID_RNET: "",
  ENABLE_IDP: "",
  REDIRECT_URI_OKTA: "",
  REQUESTED_SCOPES_OKTA: "",
  REQUESTED_SCOPES_RNET: "",
  AUTHORIZATION_ENDPOINT_OKTA: "",
}

const getConfiguration = (key: keyof Configuration) => window?.[WINDOW_PROPERTIES.CONFIGURATION]?.[key]

const loadConfiguration = () =>
  fetch(CONFIGURATION_FILE)
    .then((r) => r.json())
    .then((data) => {
      Object.values(CONFIG).forEach((k: CONFIG) => (window[WINDOW_PROPERTIES.CONFIGURATION][k] = data[k]))
    })

export { getConfiguration, loadConfiguration }
