import styled from "@emotion/styled"

import { fonts } from "assets/fonts/fonts"

export const LogoText = styled.span`
  font-family: ${fonts.bolder};
  padding-left: 6px;
  font-size: 26px;
`
export const USB = styled.span`
  font-size: 6rem;
  line-height: 0;
  display: flex;
  flex: 1;
  height: 100%;
  align-items: center;
  justify-content: center;
`
export const BlockTitle = styled.span`
  font-size: 18px;
`
export const Form = styled.form`
  display: flex;
  width: 100%;
`
