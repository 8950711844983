import React from "react"

import CircularProgress from "@mui/material/CircularProgress"
import styled from "styled-components"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  min-height: 100vh;
  z-index: 50;
`

const AbsoluteLoader = () => {
  return (
    <Container>
      <CircularProgress size={42} />
    </Container>
  )
}

export default AbsoluteLoader
