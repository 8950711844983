import React, { useEffect, useRef, useState } from "react"

import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import DeleteIcon from "@mui/icons-material/Delete"
import EventIcon from "@mui/icons-material/Event"
import ModeEditIcon from "@mui/icons-material/ModeEdit"
import DatePicker from "@mui/lab/DatePicker"
import type { TextFieldProps } from "@mui/material"
import { Box, Button, Dialog, Fab, Grid, TextField } from "@mui/material"
import { AxiosError } from "axios"
import dateFormat from "date-fns/format"
import { useSnackbar } from "notistack"
import { FormattedDate, FormattedMessage, FormattedTime, useIntl } from "react-intl"

import api from "api/api"
import dekraLogo from "assets/images/dekra.svg"
import renaultLogo from "assets/images/renault_logo.svg"
import { COLORS } from "assets/styles/colors"
import InfoBlock from "components/infoBlock"
import { AbsoluteLoader } from "components/loader"
import { Title } from "components/typography/Title"
import { getConfiguration } from "config/env"
import { AgendaEvent, PrepareAgendaEvent, Receptionist } from "types/common.types"
import { CONFIG, ERRORS, EXPERTS } from "types/enums.types"
import { CreateEventForm } from "types/form.types"
import { getErrorMessages } from "utils/error.utils"
import { cleanLicensePlate, formatDate, formatDateTime } from "utils/search.utils"

import Form from "./Agenda.form"
import * as S from "./Agenda.styles"

const Agenda = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const [modal, setModal] = useState<boolean>(false)
  const [date, setDate] = useState(new Date())
  const [licensePlate, setLicensePlate] = useState("")
  const [vehicle, setVehicle] = useState<PrepareAgendaEvent>()
  const [agenda, setAgenda] = useState<Map<string, AgendaEvent[]>>(new Map())
  const [error, setError] = useState(false)
  const [edit, setEdit] = useState(false)
  const [isScrollToTopButtonVisible, setIsScrollToTopButtonVisible] = useState(false);

  const today = new Date()
  const indicatorKey = useRef("")
  const indicatorIndex = useRef(-1)

  if (agenda.size > 0) {
    indicatorIndex.current = -1
    // compute key
    indicatorKey.current = ""
    const todayDate = dateFormat(today, "YYY-MM-dd")

    const keys = Array.from(agenda.keys())
    // Compute date group
    for (let i = 0; i < keys.length; i++) {
      if (todayDate >= keys[i]) {
        indicatorKey.current = keys[i]
      }
    }

    const days = agenda.get(indicatorKey.current)
    // Compute index on date group
    if (days) {
      for (let i = 0; i < days.length; i++) {
        if (today >= new Date(days[i].date)) {
          indicatorIndex.current = i
        }
      }
    }
  }

  const { enqueueSnackbar } = useSnackbar()

  const intl = useIntl()

  const onError = (e: unknown) => {
    setError(true)
    setVehicle(undefined)

    const messages = getErrorMessages(e as AxiosError)
    messages.forEach((m) => enqueueSnackbar(intl.formatMessage({ id: m }), { variant: "error" }))
  }

  const onEdit = async (licensePlate: string) => {
    setLoading(true)
    try {
      const { data } = await api.agenda.getVehicleDetails({ licensePlate, group: EXPERTS.RENAULT, force: true })

      setVehicle(data)
      setError(false)
      setEdit(true)
    } catch (e) {
      onError(e)
    } finally {
      setLoading(false)
      scrollToTop()
    }
  }

  const onSearch = async () => {
    setLoading(true)
    try {
      const { data } = await api.agenda.getVehicleDetails({ licensePlate, group: EXPERTS.RENAULT, force: false })

      setVehicle(data)
      setError(false)
      setEdit(false)
    } catch (e) {
      const data = (e as AxiosError)?.response?.data
      if (data?.error == ERRORS.RESTIT_IN_PROGRESS) {
        setModal(true)
      } else if (data?.error == ERRORS.RESTIT_ALREADY_TODO) {
        enqueueSnackbar(
          intl.formatMessage({ id: `ERROR.${ERRORS.RESTIT_ALREADY_TODO}` }, { date: formatDateTime(intl, new Date(data.meta.date)) }),
          { variant: "error" }
        )
      } else {
        onError(e)
      }
    } finally {
      setLoading(false)
      scrollToTop()
    }
  }

  const onConfirmModal = async () => {
    setLoading(true)
    setModal(false)
    try {
      const { data } = await api.agenda.getVehicleDetails({ licensePlate, group: EXPERTS.RENAULT, force: true })

      setVehicle(data)
      setError(false)
      setEdit(false)
    } catch (e) {
      onError(e)
    } finally {
      setLoading(false)
      scrollToTop()
    }
  }

  const onDelete = async (id: string) => {
    setLoading(true)
    try {
      await api.agenda.deleteEvent(id)
      await loadAgenda()
      enqueueSnackbar(intl.formatMessage({ id: "screen.agenda.delete.success" }), { variant: "success" })
    } catch (e) {
      const messages = getErrorMessages(e as AxiosError)
      messages.forEach((m) => enqueueSnackbar(intl.formatMessage({ id: m }), { variant: "error" }))
    } finally {
      setLoading(false)
    }
  }

  const loadAgenda = async () => {
    setLoading(true)

    try {
      if (date && date.getFullYear() > 2010) {
        const maxDate = new Date(date)
        maxDate.setDate(maxDate.getDate() + 30)

        const { data } = await api.agenda.get({
          ["date.min"]: dateFormat(date, "YYY-MM-dd"),
          ["date.max"]: dateFormat(maxDate, "YYY-MM-dd"),
        })
        const { results } = data
        const agendaMap: Map<string, AgendaEvent[]> = new Map()

        results?.forEach((r) => {
          const d = dateFormat(new Date(r.date), "YYY-MM-dd")
          const value = agendaMap.get(d)
          const event = { ...r, date: new Date(r.date + "Z").toISOString() }
          if (value) {
            agendaMap.set(
              d,
              [...value, event].sort((a, b) => (new Date(a.date) > new Date(b.date) ? 1 : -1))
            )
          } else {
            agendaMap.set(d, [event])
          }
        })

        setAgenda(agendaMap)
      }
    } catch (e) {
      onError(e)
    } finally {
      setLoading(false)
    }
  }

  const onIncreaseDate = () => {
    const d = new Date(date)
    d.setDate(d.getDate() + 1)
    setDate(d)
  }

  const onDecreaseDate = () => {
    const d = new Date(date)
    d.setDate(d.getDate() - 1)
    setDate(d)
  }

  const onSubmitCreateEvent = async (values: CreateEventForm) => {
    setLoading(true)
    try {
      const params = {
        ...values,
        licensePlate: vehicle?.licensePlate,
        date: values.date.toISOString().replace("Z", ""),
      }

      if (edit) {
        await api.agenda.updateEvent(params)
      } else {
        await api.agenda.createEvent(params)
      }

      setError(false)
      setVehicle(undefined)
      enqueueSnackbar(intl.formatMessage({ id: "screen.agenda.create.success" }), { variant: "success" })

      await loadAgenda()
    } catch (e) {
      const messages = getErrorMessages(e as AxiosError)
      messages.forEach((m) => enqueueSnackbar(intl.formatMessage({ id: m }), { variant: "error" }))
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    loadAgenda()
  }, [date])

  const showCompany = (receptionist: Receptionist) => {
    if (receptionist?.company?.toUpperCase() === EXPERTS.RENAULT.toUpperCase()) {
      return <S.Logo src={renaultLogo} />
    } else return <S.Logo src={dekraLogo} />
  }

  const showReceptionist = (receptionist: Receptionist) => {
    if (receptionist?.company?.toUpperCase() === EXPERTS.RENAULT.toUpperCase()) {
      return `${receptionist?.firstName ?? ""} ${receptionist?.lastName ?? ""}`
    } else return `DEKRA ${receptionist?.office ?? ""}`
  }

  const toggleScrollToTopButtonVisibility = () => setIsScrollToTopButtonVisible(window.scrollY > 100);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleScrollToTopButtonVisibility);

    return () => {
      window.removeEventListener("scroll", toggleScrollToTopButtonVisibility);
    };
  }, []);

  return (
    <Grid container>
      {loading && <AbsoluteLoader />}
      <Grid xs={12} lg={6} item container p={2} sx={{ height: "fit-content" }}>
        <Dialog onClose={() => setModal(false)} open={modal}>
          <Box p={2}>
            <Title>
              <FormattedMessage id="screen.agenda.inProgress.title" />
            </Title>
            <FormattedMessage id="screen.agenda.inProgress.body" />
            <Box pt={2} justifyContent="flex-end" display="flex">
              <Box mr={1}>
                <Button variant="contained" onClick={onConfirmModal}>
                  <FormattedMessage id="screen.agenda.inProgress.confirm" />
                </Button>
              </Box>
              <Button variant="outlined" onClick={() => setModal(false)}>
                <FormattedMessage id="screen.agenda.inProgress.cancel" />
              </Button>
            </Box>
          </Box>
        </Dialog>
        <Grid item xs={12} pt={2} pb={2} container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Grid item container alignItems="center">
              <S.IconContainer onClick={onDecreaseDate}>
                <ChevronLeftIcon fontSize="inherit" />
              </S.IconContainer>
              <S.IconContainer onClick={onIncreaseDate}>
                <ChevronRightIcon fontSize="inherit" />
              </S.IconContainer>
              <Title>
                <FormattedDate value={date} year="numeric" month="long" day="numeric" />
              </Title>
            </Grid>
          </Grid>
          <Grid item>
            <DatePicker
              label={intl.formatMessage({
                id: "search.filter.date.min",
              })}
              inputFormat="dd/MM/yyyy"
              value={date}
              InputAdornmentProps={{ position: "start" }}
              onChange={(date: "" | Date | null) => {
                setDate(date as any)
              }}
              renderInput={(params: TextFieldProps) => <TextField {...params} />}
            />
          </Grid>
        </Grid>
        <Grid container sx={{ border: `1px solid ${COLORS.GREY_BG}` }}>
          {Array.from(agenda.keys()).map((key) => (
            <Grid container key={key}>
              <Grid item container sx={{ backgroundColor: COLORS.PRIMARY_LIGHT }} p={1}>
                <FormattedDate value={key} year="numeric" month="long" day="2-digit" />
              </Grid>
              {agenda.get(key)?.map(({ tenant, id, date, signatory, licensePlate, receptionist }, i) => (
                <React.Fragment key={id}>
                  {key === indicatorKey.current && i === 0 && -1 === indicatorIndex.current && (
                    <S.SeparatorContainer>
                      <S.SeparatorCircle />
                      <S.SeparatorBar />
                    </S.SeparatorContainer>
                  )}
                  <Grid item container key={id} spacing={1} p={1} sx={{ opacity: new Date(date) < today ? 0.5 : 1 }}>
                    <Grid item xs={2}>
                      <div>
                        <FormattedTime value={new Date(date)} hour="numeric" minute="numeric" />
                      </div>
                    </Grid>
                    <Grid item xs={3} container>
                      <div>{`${tenant?.lastName ?? ""} ${tenant?.lastName && (signatory?.firstName || signatory?.lastName) ? "-" : ""} ${
                        signatory?.firstName ?? ""
                      } ${signatory?.lastName ?? ""}`}</div>
                    </Grid>
                    <Grid item xs={2}>
                      <div>{licensePlate}</div>
                    </Grid>
                    <Grid item xs={3} container>
                      <Box display="flex" alignItems="center">
                        {showCompany(receptionist)}
                        {`\u00a0`}
                        {showReceptionist(receptionist)}
                      </Box>
                    </Grid>
                    <Grid item xs={2} container justifyContent="flex-end">
                      <S.SmallIconContainer onClick={() => onEdit(licensePlate)}>
                        <ModeEditIcon fontSize="inherit" />
                      </S.SmallIconContainer>
                      {receptionist?.company?.toUpperCase() === EXPERTS.RENAULT.toUpperCase() && (
                        <S.SmallIconContainer onClick={() => onDelete(id)}>
                          <DeleteIcon fontSize="inherit" />
                        </S.SmallIconContainer>
                      )}
                    </Grid>
                  </Grid>
                  {key === indicatorKey.current && i === indicatorIndex.current && (
                    <S.SeparatorContainer>
                      <S.SeparatorCircle />
                      <S.SeparatorBar />
                    </S.SeparatorContainer>
                  )}
                </React.Fragment>
              ))}
            </Grid>
          ))}
          {agenda.size === 0 && (
            <S.BigContainer>
              <EventIcon fontSize="inherit" />
              <S.SmallIconContainer>
                <FormattedMessage id="screen.agenda.noResults" />
              </S.SmallIconContainer>
            </S.BigContainer>
          )}
        </Grid>
      </Grid>
      <Grid xs={12} lg={6} item container p={2} sx={{ backgroundColor: COLORS.GREY_BG }}>
        <Grid item container sx={{ height: "fit-content" }}>
          <Grid item xs={12}>
            <Title>
              <FormattedMessage id="screen.agenda.title" />
            </Title>
          </Grid>
          <Grid item xs={12} pb={1}>
            <FormattedMessage id="screen.agenda.create.event" />
          </Grid>
          <Grid item container xs={12}>
            <S.Form
              onSubmit={(e) => {
                e.preventDefault()
                onSearch()
              }}
            >
              <Grid item container xs={8}>
                <TextField
                  sx={{ backgroundColor: "white" }}
                  size={"small"}
                  fullWidth
                  value={licensePlate}
                  onChange={(e) => setLicensePlate(cleanLicensePlate(e.target.value))}
                />
              </Grid>
              <Grid item container xs={4} pl={2}>
                <Button type="submit" variant="contained" sx={{ width: "100%" }}>
                  {intl.formatMessage({ id: "button.search.label" })}
                </Button>
              </Grid>
            </S.Form>
          </Grid>
          <Grid item container xs={12} mt={1} mb={1} pt={1} pb={1} justifyContent="center" sx={{ height: "fit-content" }}>
            <Title>
              {error && <FormattedMessage id="screen.agenda.search.error" />}
              {!error && vehicle && <FormattedMessage id="screen.agenda.search.success" />}
            </Title>
          </Grid>
          {vehicle && (
            <>
              <Grid item container xs={6} sx={{ height: "fit-content" }}>
                <Grid item container xs={12}>
                  <Grid item container xs={12}>
                    <Title>
                      <FormattedMessage id="screen.agenda.section.vehicle" />
                    </Title>
                  </Grid>

                  <Grid item xs={12} pt={1}>
                    <S.Subtitle>{`${vehicle.brand} ${vehicle.model}`}</S.Subtitle>
                  </Grid>

                  <Grid item container xs={6} pt={1} pb={1}>
                    <InfoBlock title={`restitution.chassis`} content={vehicle.chassis} />
                  </Grid>

                  <Grid item container xs={6} pt={1} pb={1}>
                    <InfoBlock title={`restitution.firstRegistrationDate`} content={formatDate(intl, vehicle.firstRegistrationDate)} />
                  </Grid>

                  <Grid item container xs={6} pt={1} pb={1}>
                    <InfoBlock title={`restitution.contractId`} content={vehicle.contractId} />
                  </Grid>
                  <Grid item container xs={6} pt={1} pb={1}>
                    <InfoBlock
                      title="restitution.contractType"
                      content={intl.formatMessage({ id: `restitution.contractType.${vehicle.contractType}` })}
                    />
                  </Grid>
                  <Grid item container xs={6} pt={1} pb={1}>
                    <InfoBlock title={`restitution.buyer`} content={vehicle.buyer} />
                  </Grid>
                </Grid>

                {vehicle?.tenant && (
                  <Grid item container xs={12}>
                    <Grid item container xs={12} pt={2}>
                      <Title>
                        <FormattedMessage id="screen.agenda.section.tenant" />
                      </Title>
                    </Grid>
                    <Grid item container xs={6} pb={1} pt={1}>
                      <InfoBlock title={`restitution.tenant.name`} content={vehicle?.tenant.lastName} />
                    </Grid>
                    <Grid item container xs={6} pb={1} pt={1}>
                      <InfoBlock title={`restitution.tenant.siret`} content={vehicle?.tenant.siret} />
                    </Grid>
                    <Grid item container xs={12} pb={1} pt={1}>
                      <InfoBlock title={`restitution.tenant.address`} content={vehicle?.tenant.address} />
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Grid item container xs={6} sx={{ height: "fit-content" }}>
                <S.Link href={getConfiguration(CONFIG.DEKRA_PORTAL_URL)} target="_blank">
                  <Button variant="contained" fullWidth>
                    {intl.formatMessage({ id: "screen.agenda.button.dekra" })}
                  </Button>
                </S.Link>
                <Grid item container xs={12} mt={2} mb={2}>
                  <S.Separator>
                    <S.SeparatorContent>
                      <FormattedMessage id="screen.agenda.or" />
                    </S.SeparatorContent>
                  </S.Separator>
                </Grid>
                <Grid item xs={12} justifyContent="center">
                  <S.SmallText>
                    <FormattedMessage id="screen.agenda.restitution.form" />
                  </S.SmallText>
                </Grid>
                <Form onSubmit={onSubmitCreateEvent} vehicle={vehicle} />
              </Grid>
            </>
          )}
          {isScrollToTopButtonVisible && (
            <Fab
              color="primary"
              onClick={scrollToTop}
              style={{
                position: "fixed",
                bottom: "2rem",
                right: "2rem",
                zIndex: 1000,
              }}
            >
              <ArrowUpwardIcon />
            </Fab>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Agenda
