import * as React from "react"

import { COLORS } from "assets/styles/colors"
import { Damage } from "types/common.types"
import { DamageZones } from "types/enums.types"

interface Props {
  damages: Array<Damage>
  className?: string
}

/*
 * can't nest elements to use `onClick` https://github.com/react-native-svg/react-native-svg/issues/1473
 * */
/*
 * `xmlns` typescript error https://github.com/react-native-svg/react-native-svg/issues/1638
 * */
const Expertise: React.FC<Props> = ({ damages, className, ...rest }) => {
  const DamagedPositions = damages.map((damage) => damage.position)
  const colorZone = (zone: DamageZones) => (DamagedPositions.includes(zone) ? COLORS.PRIMARY : COLORS.WHITE)

  return (
    /* eslint-disable react/jsx-props-no-spreading */
    <svg viewBox="0 0 1117.23 889.51" {...rest} stroke={COLORS.BLACK} strokeWidth={4} className={className} fill="white">
      <g id="Parchoc-D">
        <line id="Parchoc-G" x1="572.34" y1="629.32" x2="646.42" y2="693.6" />
        <line x1="645.77" y1="693.36" x2="679.98" y2="693.36" />
      </g>

      <g id="_36" data-name="36" fill={colorZone(DamageZones.RIGHT_FRONT_WHEEL)}>
        <circle id="RoueAV-R" cx="818.87" cy="835.4" r="53.11" />
      </g>
      <g id="_35" data-name="35" fill={colorZone(DamageZones.RIGHT_FRONT_FENDER)}>
        <path
          id="Carrosserie-D"
          d="M1148.32,914.57c.67-6,3.44-23.86,18.15-38.4,18-17.82,41-17.86,49-17.87,8.21,0,30.22-.06,48,16.85,15.34,14.58,18.22,32.92,18.89,38.81l49.88-16.49s22.46-113.87-255.32-113.87c-13.79-18.9-117.62-68.94-117.62-68.94H848.94V914.57Z"
          transform="translate(-396.96 -90.23)"
        />
        <path d="M1305.36,954.81" transform="translate(-396.96 -90.23)" />
        <path d="M1308.94,937.45" transform="translate(-396.96 -90.23)" />
      </g>
      <g id="Clignottant-D">
        <ellipse id="Clignottant-D-2" data-name="Clignottant-D" cx="712.65" cy="752.85" rx="13.02" ry="9.45" />
      </g>
      <g id="_34" data-name="34" fill={colorZone(DamageZones.RIGHT_ROCKER_PANEL)}>
        <rect id="BasDeCaisse-G" x="332.23" y="833.74" width="419.13" height="25.96" />
      </g>
      <g id="_33" data-name="33" fill={colorZone(DamageZones.RIGHT_FRONT_DOOR)}>
        <g id="PortiereAV-D">
          <rect id="Portiere" x="461.94" y="711.4" width="184.85" height="98.38" />
          <rect id="Poignee" x="481.6" y="727.57" width="31.66" height="12.13" rx="6.06" />
        </g>
        <g id="_32" data-name="32" fill={colorZone(DamageZones.FRONT_RIGHT_WINDOW)}>
          <polygon id="Portiere-VitreAV-G" points="461.94 711.4 461.94 637.7 561.07 637.7 644.4 711.4 461.94 711.4" />
        </g>
      </g>
      <g id="_29" data-name="29" fill={colorZone(DamageZones.RIGHT_REAR_WHEEL)}>
        <circle id="RoueAR-R" cx="258.74" cy="834.64" r="53.11" />
      </g>
      <g id="_28" data-name="28" fill={colorZone(DamageZones.RIGHT_REAR_FENDER)}>
        <path
          id="Carrosserie-G-2"
          data-name="Carrosserie-G"
          d="M726.13,900c1.36,4.68.51,14.55,5.61,14.55h117.2V714.66H748.6L625,775.43s-58.72-7.15-66.89,19.91c0,0-27.07,97.53-18.39,101.62s46.47,17.61,46.47,17.61,6.22-56.42,69.49-56.42S726.13,900,726.13,900Z"
          transform="translate(-396.96 -90.23)"
        />
      </g>
      <g id="_31" data-name="31" fill={colorZone(DamageZones.RIGHT_REAR_WINDOW)}>
        <polygon id="Portiere-VitreAR-G" points="443.6 711.4 443.6 637.7 344.26 637.7 260.75 711.4 443.6 711.4" />
      </g>
      <g id="_30" data-name="30" fill={colorZone(DamageZones.RIGHT_REAR_DOOR)}>
        <path
          id="Portiere-2"
          data-name="Portiere"
          d="M655.7,858.15S714,855.94,726.13,900H840.32V801.64H655.7Z"
          transform="translate(-396.96 -90.23)"
        />
        <rect id="Poignee-2" data-name="Poignee" x="271.6" y="727.57" width="31.66" height="12.13" rx="6.06" />
      </g>
      <g id="_27" data-name="27" fill={colorZone(DamageZones.SPARE_WHEEL)}>
        <circle cx="54.56" cy="738.49" r="49.79" />
      </g>
      <g id="_26" data-name="26" fill={colorZone(DamageZones.RIGHT_REAR_VIEW_MIRROR)}>
        <ellipse cx="675.04" cy="637.7" rx="11.49" ry="23.35" />
      </g>
      <g id="Roues">
        <path
          d="M701,382.34c5.1.59-1.6-17.88-48.67-17.88S595,376.8,595,376.8c18.77-1.62,38.17-4.77,56.2-4.63,0,0,2.43,1.51-.05,6.17C679.48,380.7,696,381.76,701,382.34Z"
          transform="translate(-396.96 -90.23)"
        />
        <path
          d="M701.74,688.22c5.11-.63-1.48,17.88-48.56,18.17s-57.4-12-57.4-12c18.78,1.51,38.2,4.54,56.23,4.29,0,0,2.42-1.53-.08-6.17C680.21,690,696.75,688.82,701.74,688.22Z"
          transform="translate(-396.96 -90.23)"
        />
        <path d="M1139.4,377.13s-.25-12.5,58-9.3S1263,385.11,1263,385.11l-123.57-8" transform="translate(-396.96 -90.23)" />
        <path d="M1147.23,693.82s-.25,12.49,58,9.29,65.6-17.28,65.6-17.28l-123.57,8" transform="translate(-396.96 -90.23)" />
      </g>

      <g id="AV-2" fill={colorZone(DamageZones.FRONT_BUMPER)}>
        <path
          d="M1478,364.76l-33.19,9.7s27.57,29.62,27.57,161.62-27.57,160.6-27.57,160.6L1478,706.89s35.23,8.17,35.23-170.81S1478,364.76,1478,364.76Z"
          transform="translate(-396.96 -90.23)"
        />

        <g id="_25" data-name="25" fill={colorZone(DamageZones.RIGHT_FRONT_BUMPER)}>
          <ellipse cx="1084.98" cy="573.99" rx="7.91" ry="14.81" />
        </g>
        <g id="_24" data-name="24">
          <rect x="1087.64" y="395.8" width="17.7" height="99.39" />
        </g>
        <g id="_23" data-name="23" fill={colorZone(DamageZones.LEFT_FRONT_BUMPER)}>
          <ellipse cx="1084.98" cy="317.17" rx="7.91" ry="14.81" />
        </g>
      </g>
      <g id="AV-1">
        <path
          d="M1376.72,535.36l56.43.08c-.81-145.82-34-154.85-34-154.85-22.94-9.78-65.88,10.47-64.27,11,51.65-.51,41.8,144.26,41.8,144.26Z"
          transform="translate(-396.96 -90.23)"
        />
        <path
          d="M1433.15,535.22c-.81,145.82-34,154.85-34,154.85-22.94,9.78-65.88-10.47-64.27-11,51.65.51,41.8-144.26,41.8-144.26Z"
          transform="translate(-396.96 -90.23)"
        />

        <g id="_22" data-name="22" fill={colorZone(DamageZones.FRONT_RIGHT_HEADLIGHT)}>
          <path
            d="M1358.15,665.08l49.95,15.19s16.33-14.71,23.3-97.31c0-.37-54.9,7.66-54.9,7.66S1376.6,630.75,1358.15,665.08Z"
            transform="translate(-396.96 -90.23)"
          />
          <circle cx="998.16" cy="559.18" r="15.66" />
          <circle cx="1003.85" cy="519.22" r="15.66" />
        </g>
        <g id="_21" data-name="21">
          <path
            fill={colorZone(DamageZones.GRILL)}
            d="M1376.72,534s1,42.84-.22,57l54.9-7.65s1.88-38.12,1.75-49.19v1.07c.13-11.07-1.75-49.2-1.75-49.2l-54.9-7.65c1.23,14.15.22,57,.22,57"
            transform="translate(-396.96 -90.23)"
          />
          <line x1="990.32" y1="389.71" x2="990.32" y2="389.71" />
          <line x1="990.32" y1="498.81" x2="990.32" y2="498.81" />
          <line x1="992.32" y1="389.71" x2="992.32" y2="498.81" />
          <line x1="1005.62" y1="391.91" x2="1006.41" y2="497.01" />
          <line x1="1018.51" y1="393.71" x2="1018.51" y2="495.19" />
          <line x1="1026.93" y1="394.9" x2="1027.2" y2="494.12" />
        </g>
        <g id="_20" data-name="20" fill={colorZone(DamageZones.LEFT_FRONT_HEADLIGHT)}>
          <path
            d="M1358.15,403.91l49.95-15.19s16.33,14.7,23.3,97.3c0,.38-54.9-7.65-54.9-7.65S1376.6,438.23,1358.15,403.91Z"
            transform="translate(-396.96 -90.23)"
          />
          <circle cx="998.16" cy="329.34" r="15.66" />
          <circle cx="1003.85" cy="369.3" r="15.66" />
        </g>
      </g>

      <g id="_19" data-name="19" fill={colorZone(DamageZones.HOOD)}>
        <path
          d="M1333.38,535.06s2.62,94.72,0,97.27c1,42.9-49.46,52.09-49.46,52.09s-200,12.93-200.42,12.93c11.83-12.59,13.78-162.29,13.78-162.29v.77s-2-149.7-13.78-162.3c.42,0,200.42,12.94,200.42,12.94s50.41,9.19,49.46,52.08c2.63,2.56,0,97.28,0,97.28"
          transform="translate(-396.96 -90.23)"
        />
        <g id="Calque_45" data-name="Calque 45">
          <line x1="707.72" y1="344.91" x2="929.68" y2="409.6" />
          <line x1="707.72" y1="546.45" x2="931.38" y2="481.43" />
        </g>
      </g>
      <g id="_17" data-name="17" fill={colorZone(DamageZones.ROOF)}>
        <path
          d="M646,388.73l5.15-10.39L719.4,384s6.13,9.7,27.58,9.7H946.26S1004.6,382.62,1072,369c0,0,27-19.28,25.27,166.09,0,3.07,3.84,169.51-25.27,169.52S998.81,677,950.13,677H742s-12.08-1.53-24.33,10.72c-27.07,0-66.5,4.83-66.5,4.83l-5.39-12.3S741.23,659.09,742,658s-23.32-8.27-21.27-121.53c0-1.32-4.44-108.54,21.27-124.18l-96.22-22.25"
          transform="translate(-396.96 -90.23)"
        />
        <line x1="465" y1="444.93" x2="547.38" y2="444.93" />
        <rect x="547.38" y="436.15" width="19.57" height="19.57" />
      </g>
      <g id="_18" data-name="18" fill={colorZone(DamageZones.FRONT_WINDSHIELD)}>
        <path
          d="M989,535.83c0-131.92-19-127.23-19-130.13s102-28.42,102-24.75,9.7-25.38,15.83,154.21v.67C1081.7,715.42,1072,686.89,1072,690s-97.48-24-102-24.74,19,1.79,19-130.14"
          transform="translate(-396.96 -90.23)"
        />
      </g>
      <g id="_16" data-name="15" fill={colorZone(DamageZones.REAR_WINDSHIELD)}>
        <path
          d="M720.67,524.05C720,655.11,742,654.24,742,658s-96.43,21.72-96.22,22.25C629.36,646.41,629.36,535,629.36,535v.43s0-111.47,16.37-145.32c-.21.53,96.22,18.48,96.22,22.25s-22,2.91-21.28,134"
          transform="translate(-396.96 -90.23)"
        />
        <ellipse cx="301.8" cy="445.07" rx="10.28" ry="64.07" />
      </g>
      <g id="_15" data-name="15" fill={colorZone(DamageZones.TRUNK)}>
        <path
          d="M538,535.19S533.28,692,560.09,692s51.31,5.37,91.14,6.9c7.15-9.19-22.21-3.59-22.21-163.41v.14c0-159.83,29.36-154.22,22.21-163.41-39.83,1.53-64.34,6.89-91.14,6.89S538,535.83,538,535.83"
          transform="translate(-396.96 -90.23)"
        />
      </g>
      <g>
        <g id="_38" data-name="38" fill={colorZone(DamageZones.RIGHT_REAR_BUMPER)}>
          <path
            d="M398,535.49h40.85c0,142.21,27.57,161.62,27.57,161.62l-33.19,9.7C398,706.81,398,535.49,398,535.49Z"
            transform="translate(-396.96 -90.23)"
          />
        </g>
        <g id="_37" data-name="37" fill={colorZone(DamageZones.LEFT_REAR_BUMPER)}>
          <path
            d="M398,535.83h40.85c0-142.21,27.57-161.62,27.57-161.62l-33.19-9.7C398,364.51,398,535.83,398,535.83Z"
            transform="translate(-396.96 -90.23)"
          />
        </g>
        <g id="AR-1">
          <path
            d="M477.62,386l53.61,4.59a523.39,523.39,0,0,0,0,289.54l-53.61,5.61a637,637,0,0,1,0-299.74Z"
            transform="translate(-396.96 -90.23)"
          />
          <path d="M525.11,485.53" transform="translate(-396.96 -90.23)" />
          <polygon points="105.21 297.83 94.28 335.72 125.41 335.72 134.28 300.32 105.21 297.83" />
          <polygon points="80.66 295.72 105.21 297.83 94.28 335.72 72.33 335.72 80.66 295.72" />
          <polygon points="105.33 592.9 94.28 555.04 125.41 554.95 134.38 590.32 105.33 592.9" />
          <polygon points="80.78 595.08 105.33 592.9 94.28 555.04 72.33 555.11 80.78 595.08" />

          <g id="_14" data-name="14" fill={colorZone(DamageZones.RIGHT_REAR_HEADLIGHT)}>
            <ellipse cx="91.17" cy="362.36" rx="14.04" ry="18.64" />
          </g>
          <g id="_13" data-name="13" fill={colorZone(DamageZones.REAR_LICENSE_PLATE)}>
            <rect x="77.13" y="389.17" width="27.22" height="112.85" />
          </g>
          <g id="_12" data-name="12" fill={colorZone(DamageZones.LEFT_REAR_HEADLIGHT)}>
            <ellipse cx="91.17" cy="527.78" rx="14.04" ry="18.64" />
          </g>
        </g>
      </g>
      <g id="_10" data-name="10" fill={colorZone(DamageZones.LEFT_REAR_VIEW_MIRROR)}>
        <ellipse cx="675.04" cy="255.38" rx="11.49" ry="23.35" />
      </g>
      <g id="_9" data-name="9">
        <circle id="RoueAV-G-2" data-name="RoueAV-G" cx="819.55" cy="54.11" r="53.11" fill={colorZone(DamageZones.LEFT_FRONT_WHEEL)} />
      </g>
      <g id="_8" data-name="8" fill={colorZone(DamageZones.LEFT_FRONT_FENDER)}>
        <path
          id="Carrosserie-G-3"
          data-name="Carrosserie-G"
          d="M1149,155.4a68.47,68.47,0,0,0,20.73,37.46c18.86,17.22,41.18,17.47,47.29,17.44,6.82,0,29.79-.5,48-18.64,14.51-14.45,17.59-31.75,18.38-37.79l49.54,18.64s22.46,113.87-255.32,113.87C1063.83,305.28,960,355.32,960,355.32H849.62V155.4Z"
          transform="translate(-396.96 -90.23)"
        />
        <path d="M1329.11,237.87" transform="translate(-396.96 -90.23)" />
      </g>
      <g id="Parchoc-G-2" data-name="Parchoc-G">
        <line id="Parchoc-G-3" data-name="Parchoc-G" x1="573.02" y1="260.19" x2="647.1" y2="195.91" />
        <line x1="646.45" y1="196.15" x2="680.66" y2="196.15" />
      </g>
      <g id="Clignottant-G">
        <ellipse id="Clignottant-G-2" data-name="Clignottant-G" cx="713.33" cy="136.66" rx="13.02" ry="9.45" />
      </g>
      <g id="_7" data-name="7" fill={colorZone(DamageZones.LEFT_SIDE_SILL)}>
        <rect id="BasDeCaisse-G-2" data-name="BasDeCaisse-G" x="332.91" y="29.81" width="419.13" height="25.96" />
      </g>
      <g id="_6" data-name="6" fill={colorZone(DamageZones.LEFT_FRONT_DOOR)}>
        <g id="PortiereAV-G">
          <rect id="Portiere-3" data-name="Portiere" x="462.62" y="79.72" width="184.85" height="98.38" />
          <rect id="Poignee-3" data-name="Poignee" x="482.28" y="149.81" width="31.66" height="12.13" rx="6.06" />
        </g>
        <g id="_5" data-name="5" fill={colorZone(DamageZones.LEFT_FRONT_WINDOW)}>
          <polygon
            id="Portiere-VitreAV-G-2"
            data-name="Portiere-VitreAV-G"
            points="462.62 178.11 462.62 251.81 561.75 251.81 645.09 178.11 462.62 178.11"
          />
        </g>
      </g>
      <g id="_2" data-name="2" fill={colorZone(DamageZones.LEFT_REAR_FENDER)}>
        <path
          id="Carrosserie-G-4"
          data-name="Carrosserie-G"
          d="M726.81,170c1.36-4.68.51-14.56,5.62-14.56H849.62V355.32H749.28L625.7,294.55S567,301.7,558.81,274.64c0,0-27.07-97.53-18.38-101.62s46.46-17.62,46.46-17.62,6.22,56.43,69.49,56.43S726.81,170,726.81,170Z"
          transform="translate(-396.96 -90.23)"
        />
      </g>
      <g id="_4" data-name="4" fill={colorZone(DamageZones.LEFT_REAR_WINDOW)}>
        <polygon
          id="Portiere-VitreAR-G-2"
          data-name="Portiere-VitreAR-G"
          points="444.28 178.11 444.28 251.81 344.94 251.81 261.43 178.11 444.28 178.11"
        />
      </g>
      <g id="_3" data-name="3" fill={colorZone(DamageZones.LEFT_REAR_DOOR)}>
        <g id="PortiereAR-G">
          <path
            id="Portiere-4"
            data-name="Portiere"
            d="M656.38,211.83S714.72,214,726.81,170H841v98.38H656.38Z"
            transform="translate(-396.96 -90.23)"
          />
          <rect id="Poignee-4" data-name="Poignee" x="272.28" y="149.81" width="31.66" height="12.13" rx="6.06" />
        </g>
      </g>
      <g id="_1" data-name="1">
        <circle id="RoueAR-G-2" data-name="RoueAR-G" cx="259.43" cy="54.87" r="53.11" fill={colorZone(DamageZones.LEFT_REAR_WHEEL)} />
      </g>
    </svg>
  )
}

export default Expertise
