import React from "react"

import { useIntl } from "react-intl"
import { Route, Routes } from "react-router-dom"

import Application from "screens/application"
import Authentication from "screens/authentication"
import Password from "screens/password/Password"
import Validation from "screens/validation/Validation"

const AuthenticationRouter: React.FunctionComponent = () => {
  const intl = useIntl()

  return (
    <Routes>
      <Route path={intl.formatMessage({ id: "link.app" })} element={<Application />} />
      <Route path={intl.formatMessage({ id: "link.validateAccount" })} element={<Validation />} />
      <Route path={intl.formatMessage({ id: "link.activateAccount" })} element={<Password reset={false} />} />
      <Route path={intl.formatMessage({ id: "link.editPassword" })} element={<Password reset={true} />} />
      <Route path={"*"} element={<Authentication />} />
    </Routes>
  )
}

export default AuthenticationRouter
