import { IntlShape } from "react-intl/src/types"

import { Restitution } from "types/common.types"
import { formatDate, formatDateTime, formatNull } from "utils/search.utils"

export const settings = {
  dots: false,
  arrows: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
}

export const settingsSmall = {
  ...settings,
  arrows: false,
}

interface Key {
  name: keyof Restitution
  format?: (intl: IntlShape, value: unknown) => string
}

export const vehicleKeys: Array<Key> = [
  { name: "brand" },
  { name: "color" },
  { name: "model" },
  { name: "nbPlaces" },
  { name: "chassis" },
  { name: "firstRegistrationDate", format: formatDate },
  { name: "licensePlate" },
  { name: "type" },
  { name: "fuel" },
]

export const restitutionKeys: Array<Key> = [
  { name: "contractId" },
  {
    name: "contractType",
    format: (intl, value) => formatNull(intl.formatMessage({ id: `restitution.contractType.${value}` }))
  },
  { name: "buyer" },
  { name: "receptionPlace" },
  { name: "receptionPlaceAddress" },
  { name: "storagePlace" },
  { name: "combinedDate", format: (intl, value) => formatDateTime(intl, value) },
]
