export const COLORS = {
  GREY_BG: "rgb(242, 242, 242)",
  GREY_BG_LIGHT: "rgb(249,249,249)",
  GREY_DARK: "#888B8D",
  SECONDARY: "#e6e6e6",
  PRIMARY: "#ff7900",
  PRIMARY_LIGHT: "#ffe9e0",
  PRIMARY_LIGHT_2: "#fff8f5",
  RED: "#E53935",
  WHITE: "#fff",
  GREEN: "#4caf50",
  BLUE: "#3f51b5",
  BLACK: "#000",
}
