import agenda from "./agenda.i18n"
import balance from "./balance.i18n"
import common from "./common.i18n"
import errors from "./errors.i18n"
import forms from "./forms.i18n"
import links from "./links.i18n"

const fr = {
  locale: "fr",
  messages: {
    ...agenda,
    ...balance,
    ...common,
    ...errors,
    ...forms,
    ...links,
  },
}

export default fr
