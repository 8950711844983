import React from "react"

import {
  RangeDateFilter as RangeDateFilterType,
  RangeNumberFilter as RangeNumberFilterType,
  TermFilter as TermFilterType,
  TermSearchFilter as TermSearchFilterType,
} from "types/common.types"
import { FILTER_TYPES } from "types/enums.types"

import RangeDateFilter from "./RangeDateFilter"
import RangeNumberFilter from "./RangeNumberFilter"
import TermFilter from "./TermFilter"
import TermSearchFilter from "./TermSearchFilter"

type Props = {
  filter: TermFilterType | TermSearchFilterType | RangeNumberFilterType | RangeDateFilterType
  name: string
}

const FilterContent = ({ filter, name, ...props }: Props) => {
  switch (filter.type) {
    case FILTER_TYPES.TERM:
      return <TermFilter filter={filter} name={name} />
    case FILTER_TYPES.TERM_SEARCH:
      return <TermSearchFilter filter={filter} name={name} />
    case FILTER_TYPES.RANGE_NUMBER:
      return <RangeNumberFilter filter={filter} name={name} />
    case FILTER_TYPES.RANGE_DATE:
      return <RangeDateFilter filter={filter} name={name} />
    default:
      return <div {...props} />
  }
}

export default FilterContent
