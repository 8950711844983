import { Chip as MuiChip, InputBase } from "@mui/material"
import { styled } from "@mui/material/styles"

import { COLORS } from "assets/styles/colors"

export const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: COLORS.GREY_BG,
  "&:hover": {
    backgroundColor: "rgb(244, 244, 244)",
  },
  width: "100%",
  marginRight: 0,
  marginLeft: theme.spacing(2),
}))

export const ToolBar = styled("div")(() => ({
  position: "relative",
  display: "flex",
  width: "100%",
  height: "fit-content",
}))

export const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}))

export const Input = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
}))

export const Chip = styled(MuiChip)(() => ({
  transitionDuration: "0s",
  "&:hover": {
    backgroundColor: COLORS.PRIMARY_LIGHT,
  },
}))
