import React from "react"

import { FormattedMessage } from "react-intl"
import styled from "styled-components"

import { fonts } from "assets/fonts/fonts"
import { COLORS } from "assets/styles/colors"

type ContentProps = {
  bold?: boolean
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`
const Title = styled.div`
  font-size: 13px;
  line-height: 20px;
  color: ${COLORS.GREY_DARK};
`
const Content = styled.div`
  font-family: ${(props: ContentProps) => (props.bold ? fonts.bold : fonts.regular)};
  color: black;
`

interface Props {
  title: string
  content: string
  bold?: boolean
}

const InfoBlock = ({ title, content, bold }: Props) => (
  <Container>
    <Title>
      <FormattedMessage id={title} />
    </Title>
    <Content bold={bold}>{content}</Content>
  </Container>
)

export default InfoBlock
