import React, { useEffect } from "react"

import styled from "@emotion/styled"
import CircularProgress from "@mui/material/CircularProgress"
import { useSnackbar } from "notistack"
import { useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { useNavigate, useSearchParams } from "react-router-dom"

import { getConfiguration } from "config/env"
import actions from "store/actions"
import { CONFIG } from "types/enums.types"
import { AUTH_MODE, AUTH_TYPE, LOCAL_STORAGE } from "utils/authentication-constants.utils"
import { generateLoginUrl, getAccessToken, inIframe } from "utils/authentication.utils"
import { searchParamToObject } from "utils/search.utils"

const Container = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  justify-content: center;
  font-size: calc(10px + 2vmin);
`

interface Props {
  authMode: AUTH_MODE
  authType?: AUTH_TYPE
}

const Authenticator: React.FC<Props> = ({ authMode, authType }) => {
  const d = useDispatch()
  const navigate = useNavigate()
  const intl = useIntl()
  const { enqueueSnackbar } = useSnackbar()

  const authenticateUser = (token: string, mode: AUTH_MODE) => {
    localStorage.setItem(LOCAL_STORAGE.API_TOKEN, token)
    localStorage.setItem(LOCAL_STORAGE.AUTH_MODE, mode)
    d(actions.auth.login({ token, mode }))
  }

  const [searchParams] = useSearchParams()
  const parsed = searchParamToObject(searchParams)
  const { code, state, error } = parsed

  const onMount = async () => {
    if (error) {
      enqueueSnackbar(intl.formatMessage({ id: "error.default" }), { variant: "error" })
      return null
    }

    if (code) {
      if (localStorage.getItem("pkce_state") !== state) {
        console.error("error", "invalid state")

        if (!inIframe()) {
          localStorage.removeItem(LOCAL_STORAGE.API_TOKEN)
          localStorage.removeItem(LOCAL_STORAGE.AUTH_MODE)

          window.history.replaceState({}, "", "/")
        }

        enqueueSnackbar(intl.formatMessage({ id: "error.default" }), { variant: "error" })
      } else {
        getAccessToken(
          code as string,
          async (request, body) => {
            const access_token = authMode === AUTH_MODE.IDP ? body.access_token : body.id_token
            if (inIframe()) {
              window.parent.postMessage({ access_token })
            } else {
              localStorage.setItem(LOCAL_STORAGE.REFRESH_TOKEN, body.refresh_token)
              // Replace the history entry to remove the auth code from the browser address bar
              window.history.replaceState({}, "", "/")

              /*try {
                await api.login.portal()
              } catch (e) {
                console.error("error on calling api login after auth success")
              }*/

              const redirectTo = localStorage.getItem(LOCAL_STORAGE.REDIRECT_URL) || ""
              localStorage.setItem(LOCAL_STORAGE.REDIRECT_URL, "")

              navigate(redirectTo)
              authenticateUser(access_token, authMode)
            }
          },
          function (request, error) {
            console.error("error", error.error)
            enqueueSnackbar(intl.formatMessage({ id: "error.default" }), { variant: "error" })
          },
          authMode,
          authType
        )
      }
      // Clean these up since we don't need them anymore
      localStorage.removeItem(LOCAL_STORAGE.PKCE)
      localStorage.removeItem(LOCAL_STORAGE.PKCE_VERIFIER)
    } else {
      localStorage.setItem(LOCAL_STORAGE.AUTH_MODE, authMode)
      if (authType) {
        localStorage.setItem(LOCAL_STORAGE.AUTH_TYPE, authType)
      }

      if (getConfiguration(CONFIG.ENV) === "DEV") {
        localStorage.setItem(LOCAL_STORAGE.API_TOKEN, getConfiguration(CONFIG.DEV_TOKEN))
        localStorage.setItem(LOCAL_STORAGE.AUTH_MODE, AUTH_MODE.IDP)
        const redirectTo = localStorage.getItem(LOCAL_STORAGE.REDIRECT_URL) || ""
        navigate(redirectTo)
        authenticateUser(CONFIG.DEV_TOKEN, authMode)
      } else {
        //localStorage.setItem("redirectUrl", window.location.pathname)
        // Redirect to the authorization server
        window.location.href = await generateLoginUrl(false, authMode, authType)
      }
    }
  }

  useEffect(() => {
    onMount()
  }, [])

  return (
    <Container>
      <CircularProgress />
    </Container>
  )
}

export default Authenticator
