import { grey } from "@mui/material/colors"
import { createTheme } from "@mui/material/styles"

import { COLORS } from "./colors"

export const theme = createTheme({
  palette: {
    primary: { main: COLORS.PRIMARY },
    secondary: { main: grey[600] },
    contrastThreshold: 2,
  },
  typography: {
    fontSize: 13,
    fontFamily: ["RCI Book", "Roboto", "-apple-system", "BlinkMacSystemFont", '"Segoe UI"', '"Helvetica Neue"', "Arial", "sans-serif"].join(
      ","
    ),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: "none !important",
          borderRadius: 0,
          fontFamily: "RCI Bold",
        },
        contained: {
          boxShadow: "none",
        },
      },
    },
  },
})
