import React from "react"

import { FormattedMessage } from "react-intl"

import { useReduxDispatch } from "store"
import actions from "store/actions"
import { TermFilter } from "types/common.types"
import { isAnyTermChecked } from "utils/search.utils"

import * as S from "./Filter.style"

type Props = {
  filter: TermFilter
  name: string
}

const compare = (a: string, b: string) => {
  if (a > b) {
    return 1
  }
  if (b > a) {
    return -1
  }
  return 0
}

const ToggleFilter = ({ filter, name }: Props) => {
  const dispatch = useReduxDispatch()

  if (!filter || !filter.terms) return <div />

  const { terms } = filter

  const anySelected = isAnyTermChecked(filter)

  return (
    <S.Container>
      <S.Toggle enabled={!anySelected} onClick={() => dispatch(actions.search.resetFilter(name))}>
        <FormattedMessage id="button.toggle.all" />
      </S.Toggle>
      {terms &&
        [...terms]
          .sort((a, b) => compare(a.term, b.term))
          .map((t) => (
            <S.Toggle
              key={t.term}
              enabled={t.checked}
              onClick={() => dispatch(actions.search.checkTerm({ filter: name, name: t.term, reset: true }))}
            >
              <FormattedMessage id={`restitution.contractType.${t.term}`} />
            </S.Toggle>
          ))}
    </S.Container>
  )
}

export default ToggleFilter
