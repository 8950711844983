import React, { useState } from "react"

import { Grid } from "@mui/material"
import { AxiosError } from "axios"
import dateFormat from "date-fns/format";
import { useSnackbar } from "notistack"
import { FormattedMessage, useIntl } from "react-intl"

import api from "api/api"
import { COLORS } from "assets/styles/colors"
import { AbsoluteLoader } from "components/loader"
import { Title } from "components/typography/Title"
import type { BalanceResult } from "types/common.types";
import { ERRORS } from "types/enums.types";
import type { BalanceForm } from "types/form.types"
import { calculItems, contractItems, tenantItems, vehicleItems } from "utils/balance.utils";
import { getErrorMessages } from "utils/error.utils"
import { formatPrice } from "utils/search.utils";

import Form from "./Balance.form"
import {
  BalanceResumeContent,
  BalanceResumeTitle,
  ErrorMessage,
  MainNumberContainer,
  MainNumberContent,
  MainNumberTitle,
  Subtitle,
  WarningMessage,
} from "./Balance.styles";

const LiseErrorCodes = ["4042", "4043", "9008", "7277", "5017", "6541", "6710", "9007", "9013"]
const LiseWarningCodes = ["9009"]

const Balance = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [balance, setBalance] = useState<BalanceResult>()

  const { enqueueSnackbar } = useSnackbar()

  const intl = useIntl()

  const onSubmit = async (values: BalanceForm) => {
    setLoading(true)
    try {
      const params = {
        ...values,
        startDate: dateFormat(values.startDate, "YYY-MM-dd")
      }

      const res = await api.contract.clearance(params)
      if (res) {
        setBalance(res.data)
      } else {
        setBalance(undefined)
      }
    } catch (e) {
      setBalance(undefined)
      const messages = getErrorMessages(e as AxiosError)
      messages.forEach((m) => enqueueSnackbar(intl.formatMessage({ id: m }), { variant: "error" }))
    } finally {
      setLoading(false)
    }
  }

  return (
    <Grid container>
      {loading && <AbsoluteLoader />}
      <Grid xs={12} lg={6} item container p={2} sx={{ height: "fit-content" }}>
        <Form onSubmit={onSubmit} />
      </Grid>
      <Grid xs={12} lg={6} item container p={2} sx={{ backgroundColor: COLORS.GREY_BG }}>
        <Grid item container sx={{ height: balance ? "fit-content" : "100%" }}>
          {!balance &&
            <Grid container item xs={12} display={"flex"} flexDirection={"column"} justifyContent={"center"} textAlign={"center"} sx={{ height: "100%" }}>
              <Subtitle>
                <FormattedMessage id="screen.balance.noResult" />
              </Subtitle>
            </Grid>}
          {balance &&
            <Grid container item xs={12}>

              <Grid container item xs={12}>
                <Grid item xs={6} p={3}>
                  <MainNumberContainer>
                    <MainNumberTitle>
                      <FormattedMessage id="screen.balance.clearance" />
                    </MainNumberTitle>
                    <MainNumberContent>{formatPrice(intl, balance.clearance)}</MainNumberContent>
                  </MainNumberContainer>
                </Grid>
                <Grid item xs={6} p={3}>
                  <MainNumberContainer>
                    <MainNumberTitle>
                      <FormattedMessage id="screen.balance.balanceSheet" />
                    </MainNumberTitle>
                    <MainNumberContent>{formatPrice(intl, balance.balanceSheet)}</MainNumberContent>
                  </MainNumberContainer>
                </Grid>
              </Grid>

              {balance?.liseTechOutputErrorCode && LiseWarningCodes.includes(balance.liseTechOutputErrorCode) &&
                <Grid container item xs={12} pb={1}>
                  <WarningMessage>
                    <FormattedMessage id={`ERROR.${ERRORS.CLEARANCE_ERROR}.${balance.liseTechOutputErrorCode}`} />
                  </WarningMessage>
                </Grid>
              }

              {balance?.liseTechOutputErrorCode && LiseErrorCodes.includes(balance.liseTechOutputErrorCode) &&
                <Grid container item xs={12} pb={1}>
                  <ErrorMessage>
                    <FormattedMessage id={`ERROR.${ERRORS.CLEARANCE_ERROR}.${balance.liseTechOutputErrorCode}`} />
                  </ErrorMessage>
                </Grid>
              }

              <Grid container item xs={12} pb={1}>
                <Title>
                  <FormattedMessage id="screen.balance.calcul" />
                </Title>
                <Grid container item xs={12} pt={1}>
                  {calculItems.map(item => (
                    <Grid key={`contract_${item.key}`} item xs={6}>
                      <BalanceResumeTitle>
                        <FormattedMessage id={`screen.balance.calcul.${item.key}`} />
                      </BalanceResumeTitle>
                      <BalanceResumeContent>
                        {item.format ? item.format(intl, balance[item.key]) : balance[item.key] ?? "-"}
                      </BalanceResumeContent>
                    </Grid>))}
                </Grid>
              </Grid>

              <Grid container item xs={12}>
                <Title>
                  <FormattedMessage id="screen.balance.contract" />
                </Title>
                <Grid container item xs={12} pt={1}>
                  {contractItems.map(item => (
                    <Grid key={`contract_${item.key}`} item xs={6}>
                      <BalanceResumeTitle>
                        <FormattedMessage id={`screen.balance.contract.${item.key}`} />
                      </BalanceResumeTitle>
                      <BalanceResumeContent>
                        {item.format ? item.format(intl, balance.contract[item.key]) : balance.contract[item.key] ?? "-"}
                      </BalanceResumeContent>
                    </Grid>))}
                </Grid>
              </Grid>

              <Grid container item xs={12} pt={2}>
                <Title>
                  <FormattedMessage id="screen.balance.vehicle" />
                </Title>
                <Grid container item xs={12} pt={1}>
                  {vehicleItems.map(item => (
                    <Grid key={`vehicle_${item.key}`} item xs={6}>
                      <BalanceResumeTitle>
                        <FormattedMessage id={`screen.balance.vehicle.${item.key}`} />
                      </BalanceResumeTitle>
                      <BalanceResumeContent>
                        {item.key === "type" ?
                          `${balance.vehicle["type"]} / ${balance.vehicle["subType"]}`
                          : item.format ? item.format(intl, balance.vehicle[item.key]) : balance.vehicle[item.key] ?? "-"}
                      </BalanceResumeContent>
                    </Grid>))}
                </Grid>
              </Grid>

              <Grid container item xs={12} pt={2}>
                <Title>
                  <FormattedMessage id="screen.balance.tenant" />
                </Title>
                <Grid container item xs={12} pt={1}>
                  {tenantItems.map(item => (
                    <Grid key={`tenant_${item.key}`} item xs={6}>
                      <BalanceResumeTitle>
                        <FormattedMessage id={`screen.balance.tenant.${item.key}`} />
                      </BalanceResumeTitle>
                      <BalanceResumeContent>
                        {item.format ? item.format(intl, balance.tenant[item.key]) : balance.tenant[item.key] ?? "-"}
                      </BalanceResumeContent>
                    </Grid>))}
                </Grid>
              </Grid>
            </Grid>
          }
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Balance
