import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"

import { GetSearchResults } from "types/api.types"
import { FILTER_TYPES, FILTERS } from "types/enums.types"
import { resetFilter as resetFilterObject } from "utils/search.utils"

import { SearchState } from "./search.types"

const initialState: SearchState = {
  results: undefined,
  refresh: false,
  q: "",
  page: 1,
  nbResults: -1,
  size: 20,
  filters: {},
}

const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    search: (state, action: PayloadAction<GetSearchResults>) => {
      // immutable thanks to redux-toolkit
      const { results, page, size, nbResults, ...rest } = action.payload
      state.refresh = false
      state.results = results
      state.page = page
      state.nbResults = nbResults
      state.size = size

      state.filters = { ...rest }

      const contractFilter = state.filters[FILTERS.CONTRACT_TYPE]
      if (contractFilter && contractFilter.type === FILTER_TYPES.TERM) {
        contractFilter.valid = false
      }
    },
    checkTerm: (state, action: PayloadAction<{ filter: string; name: string; reset?: boolean }>) => {
      const { name, filter, reset } = action.payload
      const f = state.filters[filter]

      if (f && (f.type === FILTER_TYPES.TERM || f.type === FILTER_TYPES.TERM_SEARCH) && f.terms) {
        f.terms = f.terms.map((term) =>
          term.term === name ? { ...term, checked: !term.checked } : reset ? { ...term, checked: false } : term
        )

        state.page = 1
        state.refresh = true
      }
    },
    q: (state, action: PayloadAction<string>) => {
      state.q = action.payload
      state.page = 1
      state.refresh = true
    },
    page: (state, action: PayloadAction<number>) => {
      state.page = action.payload
      state.refresh = true
    },
    resetRefresh: (state) => {
      state.refresh = false
    },
    triggerRefresh: (state) => {
      state.refresh = true
    },
    resetFilter: (state, action: PayloadAction<string>) => {
      const f = state.filters[action.payload]

      state.refresh = true
      state.filters[action.payload] = resetFilterObject(f)
    },
    resetAllFilter: (state) => {
      state.filters = Object.keys(state.filters).reduce((prev, cur) => ({
        ...prev,
        [cur]: resetFilterObject(state.filters[cur])
      }), {})
      state.refresh = true
    },
    changeRange: (state, action: PayloadAction<{ filter: string; min?: number | string; max?: number | string }>) => {
      const { min, max, filter } = action.payload
      const f = state.filters[filter]

      if (f && (f.type === FILTER_TYPES.RANGE_NUMBER || f.type === FILTER_TYPES.RANGE_DATE)) {
        if (min) {
          f.selectedMin = min
        }
        if (max) {
          f.selectedMax = max
        }
        state.page = 1
        state.refresh = true
      }
    },
  },
})

export const {
  search,
  checkTerm,
  q,
  changeRange,
  resetRefresh,
  resetFilter,
  resetAllFilter,
  triggerRefresh,
  page
} = searchSlice.actions
export default searchSlice.reducer
