import React, { useState } from "react"

import { Box, Collapse } from "@mui/material"

import { useReduxDispatch } from "store"
import actions from "store/actions"
import type { RangeDateFilter, RangeNumberFilter, TermFilter, TermSearchFilter } from "types/common.types"
import { isFilterSelected } from "utils/search.utils"

import FilterContent from "./FilterContent"
import FilterTitle from "./FilterTitle"

interface Props {
  filter: TermFilter | RangeNumberFilter | TermSearchFilter | RangeDateFilter
  name: string
}

const Filter = ({ filter, name }: Props) => {
  const [expanded, setExpanded] = useState(false)
  const dispatch = useReduxDispatch()
  const onClickClose = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    dispatch(actions.search.resetFilter(name))
  }

  return (
    <Box pb={1}>
      <FilterTitle
        name={name}
        expanded={expanded}
        showClose={isFilterSelected(filter)}
        onClickExpand={() => setExpanded(!expanded)}
        onClickClose={onClickClose}
      />
      <Collapse in={expanded}>
        <FilterContent filter={filter} name={name} />
      </Collapse>
    </Box>
  )
}

export default Filter
