import React from "react"

import { Box, Button } from "@mui/material"
import { FormattedMessage } from "react-intl"

import * as S from "./Authentication.styles"

interface Props {
  onPress: () => void
  titleKey: string
  buttonKey: string
  icon: () => React.ReactNode
  twoAuthMode?: boolean
}

const AuthenticationType: React.FC<Props> = ({ onPress, titleKey, buttonKey, icon, twoAuthMode }) => {
  return (
    <Box display={"flex"} flexDirection={"column"} width={twoAuthMode ? "50%" : "100%"}>
      <Box width="100%" justifyContent="center" alignItems="center" p={2} display="flex">
        <S.BlockTitle>
          <FormattedMessage id={titleKey} />
        </S.BlockTitle>
      </Box>
      <Box width="100%" justifyContent="center" alignItems="center" flex={1}>
        <S.USB>{icon()}</S.USB>
      </Box>
      <Box p={2} height="fit-content" justifyContent="center" display="flex">
        <Button variant="contained" onClick={onPress}>
          <FormattedMessage id={buttonKey} />
        </Button>
      </Box>
    </Box>
  )
}

export default AuthenticationType
