import styled from "styled-components"

export const Circle = styled.div`
  background-color: #f84300;
  width: 36px;
  height: 36px;
  border-radius: 50%;
`

export const Container = styled.div`
  display: flex;
  align-items: center;
`

export const ImgLogo = styled.img`
  height: 35px;
`
