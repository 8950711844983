import React from "react"

import styled from "styled-components"

import { COLORS } from "assets/styles/colors"

const Container = styled.div``
const Text = styled.span`
  font-size: 10px;
  color: ${COLORS.GREY_DARK};
`

function Version() {
  return (
    <Container>
      <Text>v{process.env.REACT_APP_VERSION}</Text>
    </Container>
  )
}

export default Version
