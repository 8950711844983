import { Tooltip as MuiTooltip } from "@mui/material"
import { styled } from "@mui/material/styles"

export const RenaultLogo = styled("img")({
  width: "16px",
  marginRight: "2px",
  marginLeft: "2px",
})

export const StatusLabel = styled("span")({
  fontSize: 12,
  marginLeft: "4px",
})

export const Logo = styled("img")((props) => ({
  width: props.width,
}))

export const Tooltip = styled(MuiTooltip)({
  margin: "0 4px",
})

export const NoWrap = styled("span")({
  whiteSpace: "nowrap",
})
