import { styled } from "@mui/material/styles"
import MuiTableCell from "@mui/material/TableCell"
import MuiTableRow from "@mui/material/TableRow"

import { COLORS } from "assets/styles/colors"

type RowProps = {
  fontSize?: number
}

// ffamily -> sadly mui doesn't support transient props
type CellProps = {
  ffamily?: string
  fSize?: number
}

export const TableCell = styled(MuiTableCell)<CellProps>(({ ffamily, fSize }) => ({
  border: "none",
  fontFamily: ffamily ? ffamily : "inherit",
  padding: "16px 12px",
  fontSize: fSize ? fSize : "inherit",
}))

export const TableCellTitle = styled(TableCell)(() => ({
  border: "none",
  fontFamily: "RCI Bold",
}))

export const TableRow = styled(MuiTableRow)((props: RowProps) => ({
  cursor: "pointer",
  [`& .MuiTableCell-root`]: {
    fontSize: props.fontSize && props.fontSize,
  },
  "&:hover": {
    background: COLORS.PRIMARY_LIGHT,
  },
  "&:nth-child(even)": {
    backgroundColor: COLORS.GREY_BG,
  },
  "&:nth-child(odd)": {
    backgroundColor: COLORS.GREY_BG_LIGHT,
  },
  "&:nth-child(odd):hover": {
    backgroundColor: COLORS.PRIMARY_LIGHT,
  },
  "&:nth-child(even):hover": {
    backgroundColor: COLORS.PRIMARY_LIGHT,
  },
}))
