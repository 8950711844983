import styled from "styled-components"

type ContainerProps = {
  size?: number
}

export const Container = styled.div`
  font-size: ${(props: ContainerProps) => props.size ?? 24}px;
  display: flex;
  align-items: center;
`
