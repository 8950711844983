import React, { SyntheticEvent, useEffect, useState } from "react"

import MuiSlider from "@mui/material/Slider"
import { styled } from "@mui/material/styles"

import { useReduxDispatch } from "store"
import actions from "store/actions"
import { RangeNumberFilter as RangeNumberFilterType } from "types/common.types"

const Slider = styled(MuiSlider)(() => ({
  "& .MuiSlider-mark": {
    transform: "translateX(-50%)",
    width: 0,
  },
}))

const Container = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  backgroundColor: "white",
  maxHeight: "300px",
  padding: "12px 24px",
  zIndex: 10,
}))

interface Props {
  filter: RangeNumberFilterType
  name: string
}

const RangeNumberFilter = ({ filter, name }: Props) => {
  const { min, max, selectedMax, selectedMin } = filter
  const dispatch = useReduxDispatch()

  const minValue = Math.floor(parseFloat(min))
  /* Force different values */
  const maxValue = min !== max ? Math.ceil(parseFloat(max)) : Math.ceil(parseFloat(max)) + 10

  const initialState = [
    selectedMin || selectedMin === 0 ? selectedMin : minValue,
    selectedMax || selectedMax === 0 ? selectedMax : maxValue,
  ]
  const [value, setValue] = useState(initialState)

  /* Reset state if props change (for instance on click reset) */
  useEffect(() => {
    setValue(initialState)
    // eslint-disable-next-line
  }, [filter])

  const handleChange = (event: Event, newValue: number | number[]) => {
    if (Array.isArray(newValue)) {
      setValue(newValue)
    }
  }

  const handleChangeCommitted = (event: Event | SyntheticEvent<Element, Event>, newValue: number | number[]) => {
    if (Array.isArray(newValue)) {
      const min = newValue[0]
      const max = newValue[1]
      dispatch(actions.search.changeRange({ min, max, filter: name }))
    }
  }

  const marks = [
    {
      value: minValue,
      label: minValue,
    },

    {
      value: maxValue,
      label: maxValue,
    },
  ]

  return (
    <Container>
      <Slider
        min={minValue}
        max={maxValue}
        step={10}
        value={value}
        marks={marks}
        onChangeCommitted={handleChangeCommitted}
        onChange={handleChange}
        valueLabelDisplay="auto"
        aria-labelledby="range-slider"
        size="small"
      />
    </Container>
  )
}

export default RangeNumberFilter
