import { getConfiguration } from "config/env"
import { CONFIG } from "types/enums.types"

export default {
  init: () => `${getConfiguration(CONFIG.API_PORTAL_BASE_URL)}/user/init`,
  search: () => `${getConfiguration(CONFIG.API_PORTAL_BASE_URL)}/restit`,
  export: () => `${getConfiguration(CONFIG.API_PORTAL_BASE_URL)}/restit/export`,
  login: () => `${getConfiguration(CONFIG.API_PUBLIC_BASE_URL)}/authentication/login`,
  validation: () => `${getConfiguration(CONFIG.API_PUBLIC_BASE_URL)}/authentication/validation`,
  activate: () => `${getConfiguration(CONFIG.API_PUBLIC_BASE_URL)}/authentication/activate`,
  resetPassword: () => `${getConfiguration(CONFIG.API_PUBLIC_BASE_URL)}/authentication/reset-password`,
  createEvent: () => `${getConfiguration(CONFIG.API_PORTAL_BASE_URL)}/agenda`,
  deleteEvent: (id: string) => `${getConfiguration(CONFIG.API_PORTAL_BASE_URL)}/agenda/${id}`,
  clearance: () => `${getConfiguration(CONFIG.API_PORTAL_BASE_URL)}/contract/clearance`,
  get: (id: string) => `${getConfiguration(CONFIG.API_PORTAL_BASE_URL)}/restit/${id}`,
  preClose: (id: string) => `${getConfiguration(CONFIG.API_PORTAL_BASE_URL)}/restit/${id}/pre-close`,
  prepare: () => `${getConfiguration(CONFIG.API_PORTAL_BASE_URL)}/agenda/prepare`,
  agenda: () => `${getConfiguration(CONFIG.API_PORTAL_BASE_URL)}/agenda`,
  iosAppCode: () => `${getConfiguration(CONFIG.API_PUBLIC_BASE_URL)}/api/ios-app-code`,
}
