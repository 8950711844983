import React from "react"

import AdapterDateFns from "@mui/lab/AdapterDateFns"
import LocalizationProvider from "@mui/lab/LocalizationProvider"
import { ThemeProvider } from "@mui/material"
import CssBaseline from "@mui/material/CssBaseline"
import frLocale from "date-fns/locale/fr"
import { SnackbarProvider } from "notistack"
import ReactDOM from "react-dom"
import { IntlProvider } from "react-intl"
import { Provider as ReduxProvider } from "react-redux"

import "assets/styles/common.css"
import App from "./App"
import { theme } from "./assets/styles/theme"
import fr from "./i18n/messages"
import reportWebVitals from "./reportWebVitals"
import store from "./store"

ReactDOM.render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <IntlProvider locale={fr.locale} messages={fr.messages}>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <SnackbarProvider maxSnack={3}>
              <App />
            </SnackbarProvider>
          </ThemeProvider>
        </LocalizationProvider>
      </IntlProvider>
    </ReduxProvider>
  </React.StrictMode>,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
