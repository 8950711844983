import React, { useState } from "react"

import { Visibility, VisibilityOff } from "@mui/icons-material"
import { Button, Grid, IconButton, InputAdornment, TextField } from "@mui/material"
import { useFormik } from "formik"
import { FormattedMessage, useIntl } from "react-intl"
import { object, string } from "yup"

import * as S from "./Authentication.styles"

type Props = {
  onSubmit: (values: any) => unknown
}

const Form = ({ onSubmit }: Props) => {
  const intl = useIntl()
  const [showPassword, setShowPassword] = useState(false)

  const formik = useFormik({
    validationSchema: object().shape({
      login: string().required(intl.formatMessage({ id: "form.error.required" })),
      password: string().required(intl.formatMessage({ id: "form.error.required" })),
    }),
    initialValues: {
      login: "",
      password: "",
    },
    onSubmit: (values) => {
      onSubmit(values)
    },
  })

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  const { touched, values, errors } = formik

  return (
    <Grid item container xs={12}>
      <S.Form onSubmit={formik.handleSubmit}>
        <Grid item container xs={12}>
          <Grid item xs={12} p={1} sx={{ backgroundColor: "white" }}>
            <Grid item container xs={12} p={1}>
              <TextField
                fullWidth
                size="small"
                id="login"
                name="login"
                label={intl.formatMessage({
                  id: "form.authentication.login",
                })}
                value={values.login}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={touched.login && Boolean(errors.login)}
                helperText={touched.login && errors.login}
              />
            </Grid>
            <Grid item container xs={12} p={1}>
              <TextField
                fullWidth
                size="small"
                id="password"
                name="password"
                type={showPassword ? "text" : "password"}
                label={intl.formatMessage({
                  id: "form.authentication.password",
                })}
                value={values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={touched.password && Boolean(errors.password)}
                helperText={touched.password && errors.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword((prev) => !prev)}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Grid item container xs={12} justifyContent="center" p={1} pt={2}>
            <Button type="submit" variant="contained" disabled={!formik.isValid}>
              <FormattedMessage id="form.authentication.button.submit" />
            </Button>
          </Grid>
        </Grid>
      </S.Form>
    </Grid>
  )
}

export default Form
