import React, { useState } from "react"

import { TextField as MuiTextField } from "@mui/material"
import { useIntl } from "react-intl"
import styled from "styled-components"

import { useReduxDispatch } from "store"
import actions from "store/actions"
import { TermSearchFilter } from "types/common.types"

import VirtualizedTermList from "./VirtualizedTermList"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  max-height: 300px;
  overflow-y: auto;
`

/* Style material-ui components */
const TextField = styled(MuiTextField)(() => ({
  margin: "6px",
}))

type Props = {
  filter: TermSearchFilter
  name: string
}

const filterTerm = (input: string, term: string) => (input ? term.toLowerCase().includes(input.toLowerCase()) : true)

const TermSearchFilterComponent = ({ filter, name }: Props) => {
  const [inputText, setInputText] = useState("")
  const intl = useIntl()
  const dispatch = useReduxDispatch()

  if (!filter || !filter.terms) return <div />

  const terms = inputText ? filter.terms.filter((t) => filterTerm(inputText, t.term)) : filter.terms

  return (
    <Container>
      <TextField
        label={intl.formatMessage({ id: "button.search.label" })}
        margin="dense"
        type="search"
        value={inputText}
        onChange={(event) => {
          setInputText(event.target.value)
        }}
        variant="outlined"
      />
      {terms && (
        <VirtualizedTermList
          filter={{ ...filter, terms }}
          name={name}
          onClick={(term) => {
            dispatch(actions.search.checkTerm({ filter: name, name: term }))
          }}
        />
      )}
    </Container>
  )
}

export default TermSearchFilterComponent
