import styled from "styled-components"

import { fonts } from "assets/fonts/fonts";
import { COLORS } from "assets/styles/colors";

export const Form = styled.form`
  display: flex;
  width: 100%;
`
export const Subtitle = styled.form`
  font-family: ${fonts.bold};
`

export const BalanceResumeTitle = styled.div`
  font-size: 13px;
  color: ${COLORS.GREY_DARK};
`

export const BalanceResumeContent = styled.div`
  font-size: 13px;
`

export const WarningMessage = styled.div`
  color: ${COLORS.BLUE};
`

export const ErrorMessage = styled.div`
  color: ${COLORS.RED};
`

export const MainNumberContainer = styled.div`
  padding: 8px;
  margin: auto;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  outline: solid 1px ${COLORS.BLACK};
`

export const MainNumberTitle = styled.div`
  font-size: 13px;
  font-family: ${fonts.bold};
`

export const MainNumberContent = styled.div`
  font-size: 18px;
  font-family: ${fonts.bold};
`
