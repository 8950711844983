import React, { useState } from "react"

import { useSnackbar } from "notistack"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import { useIntl } from "react-intl"

import api from "api/api"
import appleLogo from "assets/images/apple-store.svg"
import googleLogo from "assets/images/google-store.png"
import mainLogo from "assets/images/logo-desktop.png"
import { AbsoluteLoader } from "components/loader"
import { RECAPTCHA_ACTIONS } from "types/enums.types"

import * as S from "./Application.styles"

export default function Application() {
  const intl = useIntl()
  const [loading, setLoading] = useState<boolean>(false)
  const { enqueueSnackbar } = useSnackbar()
  const { executeRecaptcha } = useGoogleReCaptcha()

  const handleAppleClick = async () => {
    setLoading(true)
    try {
      if (!executeRecaptcha) {
        enqueueSnackbar(intl.formatMessage({ id: "error.recaptcha" }), { variant: "error" })
        return
      }

      const token = await executeRecaptcha(RECAPTCHA_ACTIONS.IOS_CODE)
      const { data } = await api.iosAppCode.getCode(token)
      window.location.href = `https://apps.apple.com/redeem?code=${data}&ctx=apps`
    } catch (e) {
      enqueueSnackbar(intl.formatMessage({ id: "error.default" }), { variant: "error" })
    } finally {
      setLoading(false)
    }
  }

  return (
    <S.PageContainer>
      {loading && <AbsoluteLoader />}
      <S.RenaultLogo src={mainLogo} />
      <S.Title>{intl.formatMessage({ id: "application.title" })}</S.Title>
      <S.LogoContainer>
        <S.Image onClick={handleAppleClick} src={appleLogo} />
        <a href="https://play.google.com/store/apps/details?id=com.internalliance.restitution">
          <S.Image src={googleLogo} />
        </a>
      </S.LogoContainer>
    </S.PageContainer>
  )
}
