const balance = {
  "screen.balance.title": "Créer un rendez-vous",
  "screen.balance.noResult": "Veuillez remplir le formulaire pour obtenir le solde d’un contrat\n",
  "screen.balance.contract": "Contrat",
  "screen.balance.vehicle": "Véhicule",
  "screen.balance.tenant": "Locataire",
  "screen.balance.clearance": "Solde à date de restitution",
  "screen.balance.balanceSheet": "Bilan Kilométrique",
  "screen.balance.calcul": "Paramètres de calcul",

  // Calcul
  "screen.balance.calcul.mileage": "Kilométrage à date de la restitution",
  "screen.balance.calcul.restitutionDate": "Date de restitution",

  // Contract
  "screen.balance.contract.contractType": "Type de contrat",
  "screen.balance.contract.contractNumber": "Numéro de contrat",
  "screen.balance.contract.startDate": "Date de début",
  "screen.balance.contract.kmContracted": "Nombre km contractuel",
  "screen.balance.contract.endDate": "Date de fin",
  "screen.balance.contract.kmExceededPayment": "Coût km supplémentaire",
  "screen.balance.contract.duration": "Durée",
  "screen.balance.contract.receptionPlace": "Repreneur",

  // Vehicle
  "screen.balance.vehicle.brand": "Marque",
  "screen.balance.vehicle.firstRegistrationDate": "Date 1er MEC",
  "screen.balance.vehicle.model": "Modèle",
  "screen.balance.vehicle.type": "Genre / Type",
  "screen.balance.vehicle.licensePlate": "Immatriculation",
  "screen.balance.vehicle.salePrice": "Prix de vente",
  "screen.balance.vehicle.chassis": "Numéro de châssis",

  // Tenant
  "screen.balance.tenant.lastName": "Nom complet",
  "screen.balance.tenant.address": "Adresse",
}

export default balance
