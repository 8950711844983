import styled from "styled-components"

import { fonts } from "assets/fonts/fonts"
import { COLORS } from "assets/styles/colors"

const Centered = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Link = styled.a`
  text-decoration: none;
  width: 100%;
`

export const IconContainer = styled(Centered)`
  cursor: pointer;
  font-size: 28px;

  &:hover {
    color: ${COLORS.PRIMARY};
  }
`

export const BigContainer = styled(Centered)`
  flex: 1;
  font-size: 120px;
  color: ${COLORS.GREY_DARK};
  padding: 32px;
`

export const SmallIconContainer = styled(Centered)`
  font-size: 20px;
  padding: 0 6px;
  cursor: pointer;
  &:hover {
    color: black;
  }
`

export const Form = styled.form`
  display: flex;
  width: 100%;
`

export const Subtitle = styled.form`
  font-family: ${fonts.bold};
`

export const Separator = styled.div`
  background-color: ${COLORS.GREY_DARK};
  width: 100%;
  height: 1px;
  position: relative;
`

export const SeparatorContent = styled.div`
  position: absolute;
  background-color: ${COLORS.GREY_BG};
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 6px;
  box-sizing: border-box;
  font-size: 12px;
`

export const SmallText = styled.div`
  font-size: 12px;
  text-align: center;
`

export const SwitchText = styled.span`
  font-size: 13px;
  color: ${COLORS.GREY_DARK};
`

export const SeparatorContainer = styled.div`
  display: flex;
  height: 8px;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
`

export const SeparatorBar = styled.div`
  width: 94%;
  height: 2px;
  background-color: ${COLORS.PRIMARY};
`

export const SeparatorCircle = styled.div`
  width: 8px;
  height: 8px;
  background-color: ${COLORS.PRIMARY};
  border-radius: 8px;
`

export const Logo = styled.img`
  height: 16px;
`
