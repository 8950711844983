import styled from "styled-components"

import { fonts } from "assets/fonts/fonts"

type ContainerProps = {
  selected: boolean
}

export const TitleContainer = styled.span`
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  ${(props: ContainerProps) => props.selected && `font-family: ${fonts.bold};`}
`

type ButtonContainerProps = {
  expanded: boolean
}

export const ButtonContainer = styled.span`
  cursor: pointer;

  & svg {
    transform: rotate(${(props: ButtonContainerProps) => (props.expanded ? "180" : "0")}deg);
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
`

type ToggleProps = {
  enabled: boolean
}

export const Toggle = styled.span`
  text-transform: uppercase;
  cursor: pointer;
  font-family: ${fonts.bold};
  ${(props: ToggleProps) => props.enabled && `border-bottom: 2px solid black;`}
  ${(props: ToggleProps) => !props.enabled && `opacity: 0.5;`}
  line-height: 20px;
  padding: 3px 6px;
  font-size: 12px;
  &:hover {
    opacity: 1;
    border-bottom: 2px solid black;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  & ${Toggle} {
    margin-right: 12px;
  }
`
