import * as React from "react"
import { useCallback, useState } from "react"

import SearchIcon from "@mui/icons-material/Search"
import { Button, Popover } from "@mui/material"
import Box from "@mui/material/Box"
import debounce from "lodash/debounce"
import { useSnackbar } from "notistack"
import { FormattedMessage, useIntl } from "react-intl"

import api from "api/api"
import Filter from "components/filter"
import RangeDateFilter from "components/filter/RangeDateFilter"
import { AbsoluteLoader } from "components/loader"
import { useReduxDispatch, useReduxSelector } from "store"
import actions from "store/actions"
import { RangeDateFilter as RangeDateFilterType } from "types/common.types"
import { FILTERS } from "types/enums.types"
import { isFilterSelected, searchFiltersToQueryParams } from "utils/search.utils"

import * as S from "./HeaderSearch.styles"

interface Props {
  onChangeText: (text: string) => unknown
  initialText: string
}

const HeaderSearch = ({ onChangeText, initialText = "" }: Props) => {
  const [text, setText] = useState<string>(initialText)
  const [loading, setLoading] = useState<boolean>(false)
  const { enqueueSnackbar } = useSnackbar()
  const intl = useIntl()
  const dispatch = useReduxDispatch()

  const debouncedOnChange = useCallback(debounce(onChangeText, 800), [])

  const data = useReduxSelector((state) => state.search)
  const { filters } = data

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const onExport = async () => {
    const params = searchFiltersToQueryParams(filters)

    setLoading(true)
    try {
      const { data, headers } = await api.search.export(params)

      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement("a")
      link.href = url

      const title = headers["content-disposition"].split("=")[1].replace(/"/g, "")
      link.setAttribute("download", title) //or any other extension
      document.body.appendChild(link)
      link.click()
    } catch (e) {
      enqueueSnackbar(intl.formatMessage({ id: "error.default" }), { variant: "error" })
    } finally {
      setLoading(false)
    }
  }

  const open = Boolean(anchorEl)
  const id = open ? "simple-popover" : undefined

  const validFilters = Object.keys(filters).filter((k) => filters[k] && filters[k].valid)
  const dateFilter = filters[FILTERS.DATE]

  return (
    <Box>
      <Box display="flex" alignItems="center">
        {loading && <AbsoluteLoader />}
        <S.ToolBar>
          <Button aria-describedby={id} variant="outlined" onClick={handleClick}>
            {intl.formatMessage({ id: "button.filter" })}
          </Button>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Box p={2}>
              {validFilters && (
                <Box>
                  {validFilters.map((k) => (
                    <Filter key={k} name={k} filter={filters[k]} />
                  ))}
                  <Box display="flex" justifyContent="flex-end">
                    <Button onClick={() => dispatch(actions.search.resetAllFilter())} color="secondary" variant="outlined">
                      <FormattedMessage id="button.reset" />
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
          </Popover>
          <S.Search>
            <S.SearchIconWrapper>
              <SearchIcon />
            </S.SearchIconWrapper>
            <S.Input
              value={text}
              onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                setText(event.target.value)
                debouncedOnChange(event.target.value)
              }}
              placeholder={intl.formatMessage({ id: "search.placeholder" })}
              inputProps={{ "aria-label": "search" }}
            />
          </S.Search>
        </S.ToolBar>
        {dateFilter &&
          <RangeDateFilter direction="row" size="small" name="date" filter={dateFilter as RangeDateFilterType} />}
        <Box display="flex">
          <Button variant="contained" onClick={onExport}>
            {!loading && intl.formatMessage({ id: "button.export" })}
          </Button>
        </Box>
      </Box>
      <Box display="flex">
        {validFilters?.map(
          (k) =>
            isFilterSelected(filters[k]) && (
              <Box mr={1} key={k}>
                <S.Chip label={intl.formatMessage({ id: `filter.${k}` })} onDelete={() => dispatch(actions.search.resetFilter(k))} />
              </Box>
            )
        )}
      </Box>
    </Box>
  )
}

export default HeaderSearch
