import React, { MouseEventHandler } from "react"

import { Button } from "@mui/material"
import { styled } from "@mui/material/styles"

import { fonts } from "assets/fonts/fonts"

/* Style material-ui components */
const ButtonContainer = styled(Button)(() => ({
  textTransform: "initial",
  fontFamily: fonts.bold,
  fontSize: "14px",
  boxShadow: "none",
  minWidth: 40,
}))

type Props = {
  variant: "text" | "outlined" | "contained"
  color: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning"
  onClick?: MouseEventHandler<HTMLButtonElement>
  renderIcon?: () => React.ReactNode
}

const ActionButton = ({ renderIcon, variant = "contained", color = "primary", onClick, ...props }: Props) => (
  <ButtonContainer onClick={onClick} variant={variant} color={color} {...props}>
    {renderIcon && renderIcon()}
  </ButtonContainer>
)

export default ActionButton
