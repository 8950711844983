import { combineReducers } from "@reduxjs/toolkit"

import auth from "./auth/auth.reducer"
import search from "./search/search.reducer"

const rootReducer = combineReducers({
  auth,
  search,
})

export default rootReducer
