import { AxiosError } from "axios"

import { APIError } from "types/api.types"
import { ERRORS } from "types/enums.types";

const liseCodes = ["9009", "2910", "2773", "4042", "4043", "9008", "6541", "6710", "9007", "9013", "5017", "7277", "2321"]

export const getErrorMessages = (error: AxiosError<APIError>): string[] => {
  const type = error?.response?.data?.error
  const meta = error?.response?.data.meta

  if (meta && Array.isArray(meta) && meta.length > 0) {
    return meta.map((m) => `ERROR.${type}.${m}`)
  }

  if (typeof meta === "string" && type === ERRORS.CLEARANCE_ERROR && liseCodes.includes(meta)) {
    return [`ERROR.${type}.${meta}`]
  }

  if (type) {
    return [`ERROR.${type}`]
  }

  return ["error.default"]
}
