import React, { useEffect, useState } from "react"
import "assets/styles/common.css"

import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import { useSelector } from "react-redux"
import { BrowserRouter } from "react-router-dom"

import { AbsoluteLoader } from "./components/loader"
import { getConfiguration, loadConfiguration } from "./config/env"
import AppRouter from "./routers/AppRouter"
import AuthenticationRouter from "./routers/AuthenticationRouter"
import ScrollToTop from "./routers/ScrollToTop"
import { RootState } from "./store"
import { CONFIG } from "./types/enums.types"

function App() {
  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated)

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    ;(async () => {
      try {
        await loadConfiguration()
      } catch (e) {
        console.error("Failed to load conf file")
      } finally {
        setLoading(false)
      }
    })()
  }, [])

  if (loading) {
    return (
      <div>
        <AbsoluteLoader />
      </div>
    )
  }

  return (
    <GoogleReCaptchaProvider reCaptchaKey={getConfiguration(CONFIG.RECAPTCHA_KEY)}>
      <BrowserRouter>
        <ScrollToTop />
        {isAuthenticated ? <AppRouter /> : <AuthenticationRouter />}
      </BrowserRouter>
    </GoogleReCaptchaProvider>
  )
}

export default App
