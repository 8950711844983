import React from "react"

import { CancelOutlined, CheckCircleOutlined, CheckCircleRounded, Circle, Pending } from "@mui/icons-material"

import { COLORS } from "assets/styles/colors"
import { RESTITUTION_STATUS } from "types/enums.types"

import * as S from "./StatusLogo.styles"

interface Props {
  status: string
  size?: number
}

const StatusLogo = ({ status, size }: Props) => {
  const renderLogo = () => {
    switch (status) {
      case RESTITUTION_STATUS.PRE_CLOSED:
      case RESTITUTION_STATUS.TO_COMPLETE:
        return <CheckCircleOutlined htmlColor={COLORS.GREEN} fontSize="inherit" />
      case RESTITUTION_STATUS.DONE:
        return <CheckCircleRounded htmlColor={COLORS.GREEN} fontSize="inherit" />
      case RESTITUTION_STATUS.VALIDATED:
        return <CheckCircleOutlined htmlColor={COLORS.GREEN} fontSize="inherit" />
      case RESTITUTION_STATUS.ERROR:
        return <CancelOutlined htmlColor={COLORS.RED} fontSize="inherit" />
      case RESTITUTION_STATUS.CANCELLED:
        return <CancelOutlined htmlColor={COLORS.GREY_DARK} fontSize="inherit" />
      case RESTITUTION_STATUS.IN_PROGRESS:
        return <Pending htmlColor={COLORS.BLUE} fontSize="inherit" />
      case RESTITUTION_STATUS.WAITING_VALIDATION:
        return <Pending htmlColor={COLORS.BLUE} fontSize="inherit" />
      case RESTITUTION_STATUS.TODO:
        return <Circle htmlColor={COLORS.GREY_DARK} fontSize="inherit" />
      default:
        return <Circle htmlColor={COLORS.GREY_DARK} fontSize="inherit" />
    }
  }

  return <S.Container size={size}>{renderLogo()}</S.Container>
}

export default StatusLogo
