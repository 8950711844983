const links = {
  "link.restitutions": "/",
  "link.label.restitutions": "Mes restitutions",
  "link.planning": "/planning",
  "link.label.planning": "Mon planning",
  "link.balance": "/balance",
  "link.label.balance": "Calcul du solde",
  "link.account": "/mon-compte",
  "link.label.account": "Mon compte",
  "link.validateAccount": "/valider-une-inscription",
  "link.activateAccount": "/activer-mon-compte",
  "link.editPassword": "/changer-mon-mot-de-passe",
  "link.details": "/details/:id",
  "link.app": "/app",
}

export default links
