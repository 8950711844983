import React, { MouseEventHandler } from "react"

import { Cancel, ExpandMore } from "@mui/icons-material"
import { FormattedMessage } from "react-intl"

import { COLORS } from "assets/styles/colors"
import ActionButton from "components/buttons/ActionButton"

import * as S from "./Filter.style"

interface Props {
  name: string
  onClickExpand: (event?: unknown) => unknown
  onClickClose: MouseEventHandler<HTMLButtonElement>
  showClose: boolean
  expanded: boolean
}

const FilterComponent = ({ name, onClickClose, onClickExpand, showClose, expanded }: Props) => (
  <S.TitleContainer onClick={onClickExpand} selected={showClose}>
    <FormattedMessage id={`filter.${name}`} />
    <div>
      {showClose && (
        <ActionButton variant="text" color="secondary" onClick={onClickClose} renderIcon={() =>
          <Cancel htmlColor={COLORS.GREY_DARK} />} />
      )}
      <S.ButtonContainer expanded={expanded}>
        <ActionButton variant="text" color="secondary" onClick={onClickExpand} renderIcon={() =>
          <ExpandMore htmlColor={"black"} />} />
      </S.ButtonContainer>
    </div>
  </S.TitleContainer>
)

export default FilterComponent
