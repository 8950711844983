import axios from "axios"
import qs from "qs"

import { getConfiguration } from "config/env"
import { GetAgendaResults, GetSearchResults, LoginForm, PostCreateEventForm } from "types/api.types"
import type {
  AgendaEvent,
  BalanceParams,
  BalanceResult,
  PrepareAgendaEvent,
  Restitution,
  User
} from "types/common.types"
import { CONFIG, EXPERTS } from "types/enums.types"
import { PasswordForm } from "types/form.types"
import { LOCAL_STORAGE } from "utils/authentication-constants.utils"
import { getTokenSilently } from "utils/authentication.utils"

import endpoints from "./endpoints"

// 5 minutes
axios.defaults.timeout = 60000 * 5
const paramsSerializer = (params: Record<string, any>) => qs.stringify(params, { arrayFormat: "repeat" })

const ws = axios.create({ maxRedirects: 0 })

const api = {
  search: {
    restitution: (params: Record<string, unknown>): Promise<{ data: GetSearchResults }> =>
      ws.get(endpoints.search(), { params, paramsSerializer }),
    get: (id: string) => ws.get<Restitution>(endpoints.get(id)),
    preClose: (id: string) => ws.post(endpoints.preClose(id)),
    export: (params: Record<string, unknown>) => ws.get(endpoints.export(), {
      params,
      paramsSerializer,
      responseType: "blob"
    }),
  },
  agenda: {
    get: (params: { ["date.min"]?: string; ["date.max"]?: string }): Promise<{ data: GetAgendaResults }> =>
      ws.get(endpoints.agenda(), { params }),
    getVehicleDetails: (params: { licensePlate: string; group: EXPERTS; force: boolean }): Promise<{
      data: PrepareAgendaEvent
    }> =>
      ws.get(endpoints.prepare(), { params }),
    createEvent: (values: PostCreateEventForm) => ws.post<AgendaEvent>(endpoints.createEvent(), values),
    updateEvent: (values: PostCreateEventForm) => ws.put<AgendaEvent>(endpoints.createEvent(), values),
    deleteEvent: (id: string) => ws.delete<AgendaEvent>(endpoints.deleteEvent(id)),
  },
  login: {
    portal: (body: LoginForm) => {
      return ws.post<{ token: string; expire: number }>(endpoints.login(), body)
    },
  },
  user: {
    init: () => {
      return ws.get<User>(endpoints.init())
    },
  },
  contract: {
    clearance: (params: BalanceParams) => ws.get<BalanceResult>(endpoints.clearance(), { params, paramsSerializer }),
  },
  authentication: {
    activate: (body: PasswordForm): Promise<void> => ws.post(endpoints.activate(), body),
    resetPassword: (body: PasswordForm): Promise<void> => ws.post(endpoints.resetPassword(), body),
  },
  validate: (body: { key: string; recaptchaToken: string }): Promise<void> => ws.post(endpoints.validation(), body),
  iosAppCode: {
    getCode: (token: string): Promise<{ data: string }> =>
      ws.get(endpoints.iosAppCode(), {
        params: { token },
      }),
  },
}

ws.interceptors.request.use(async (request) => {
  /* Don't renew token if call renew */
  const token = await getTokenSilently()
  const authMode = localStorage.getItem(LOCAL_STORAGE.AUTH_MODE) ?? ""

  axios.defaults.baseURL = getConfiguration(CONFIG.API_BASE_HOSTNAME)
  ws.defaults.baseURL = getConfiguration(CONFIG.API_BASE_HOSTNAME)
  request.baseURL = getConfiguration(CONFIG.API_BASE_HOSTNAME)

  if (request.headers) {
    request.headers.Authorization = `Bearer ${token}`
    request.headers["x-agent"] = "PORTAL"

    if (authMode) {
      request.headers["x-authenticate-mode"] = authMode
    }

    if (request.url?.includes(CONFIG.API_PUBLIC_BASE_URL)) {
      request.headers["x-Gateway-APIKey"] = getConfiguration(CONFIG.API_GATEWAY_KEY_PUBLIC)
    } else {
      request.headers["x-Gateway-APIKey"] = getConfiguration(CONFIG.API_GATEWAY_KEY_PORTAL)
    }
  }

  return request
})

/* FORCE IDP TOKEN (DEV/DEBUG USAGE ONLY) */
/*
ws.interceptors.request.use(async (request: any) => {
  ws.defaults.headers.common = {
    Authorization: ENV.DEV_TOKEN,
    "x-authenticate-mode": "IDP"
  };

  if (request.headers) {
    request.headers.Authorization = ENV.DEV_TOKEN;
    request.headers["x-authenticate-mode"] = "IDP";
  }

  return request;
});
*/

const CORS_error = "NETWORK ERROR"

ws.interceptors.response.use(
  async (response) => response,
  async (error) => {
    if (axios.isCancel(error)) {
      return
    }

    if (
      error?.message.toUpperCase().includes(CORS_error) ||
      (error.response &&
        ((error.response.data && error.response.data.status && error.response.data.status === 401) ||
          (error.response.status && error.response.status === 401)))
    ) {
      /* If not logged, save current uri and reset apiToken */
      // localStorage.setItem(LOCAL_STORAGE.REDIRECT_URL, window.location.pathname);
      window.dispatchEvent(new CustomEvent("resetAuth"))
    }

    throw error
  }
)

export default api
