import * as React from "react"

import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import { FormattedMessage } from "react-intl"

import * as T from "components/table/Table.styles"
import { MechanicalDamage } from "types/common.types"
import { formatNull } from "utils/search.utils"

const keys = ["restitution.mechanicalDamages.element", "restitution.mechanicalDamages.comment"]

interface Props {
  mechanicalDamages?: Array<MechanicalDamage>
}

const MechanicalDamagesTable = ({ mechanicalDamages }: Props) => {
  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }} size="small">
        <TableHead>
          <TableRow>
            {keys.map((k: string) => (
              <T.TableCellTitle sx={{ fontSize: 12 }} align="left" key={k}>
                <FormattedMessage id={k} />
              </T.TableCellTitle>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {mechanicalDamages &&
            mechanicalDamages.length > 0 &&
            mechanicalDamages.map((row, i) => (
              <T.TableRow fontSize={12} key={row.element + "" + i}>
                <T.TableCell>{formatNull(row.element)}</T.TableCell>
                <T.TableCell>{formatNull(row.comment)}</T.TableCell>
              </T.TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default MechanicalDamagesTable
