import * as React from "react"

import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import { FormattedMessage, useIntl } from "react-intl"
import Slider from "react-slick"

import { fonts } from "assets/fonts/fonts"
import { COLORS } from "assets/styles/colors"
import * as T from "components/table/Table.styles"
import { Damage, Restitution } from "types/common.types"
import { formatDuration, formatNull, formatNullNumber, formatPrice } from "utils/search.utils"

import { settingsSmall } from "./Details.setting"
import * as S from "./Details.styles"

const keys = [
  "restitution.damages.photos",
  "restitution.damages.element",
  "restitution.damages.position",
  "restitution.damages.damage",
  "restitution.damages.operation",
  "restitution.damages.bodyDuration",
  "restitution.damages.paintDuration",
  "restitution.damages.partPrice",
  "restitution.damages.estimatedPrice",
  "restitution.damages.obsolescence",
  "restitution.damages.totalPrice",
]

interface Props {
  damages?: Array<Damage>
  restitution: Restitution
  onClickPhoto: (p: string[]) => void
}

const DamagesTable = ({ damages, restitution, onClickPhoto }: Props) => {
  const intl = useIntl()
  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }} size="small">
        <TableHead>
          <TableRow>
            {keys.map((k: string) => (
              <T.TableCellTitle sx={{ fontSize: 12 }} align="left" key={k}>
                <FormattedMessage id={k} />
              </T.TableCellTitle>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {damages &&
            damages.length > 0 &&
            damages.map((row, i) => (
              <T.TableRow fontSize={12} key={row.id + "" + i}>
                <T.TableCell onClick={() => row.photos && onClickPhoto(row.photos)}>
                  <div style={{ width: 80 }}>
                    <Slider {...settingsSmall}>
                      {row.photos &&
                        row.photos.map((p, i) => (
                          <div key={p + "" + i}>
                            <S.Photo src={p} alt="vehicle" />
                          </div>
                        ))}
                    </Slider>
                  </div>
                </T.TableCell>
                <T.TableCell>{row.element}</T.TableCell>
                <T.TableCell>{row.position ? intl.formatMessage({ id: `zone.${row.position}` }) : formatNull(row.position)}</T.TableCell>
                <T.TableCell>{formatNull(row.damage)}</T.TableCell>
                <T.TableCell>{formatNull(row.operation)}</T.TableCell>
                <T.TableCell>{formatDuration(intl, row.bodyDuration)}</T.TableCell>
                <T.TableCell>{formatDuration(intl, row.paintDuration)}</T.TableCell>
                <T.TableCell>{formatPrice(intl, row.partPrice !== 0 ? row.partPrice : undefined)}</T.TableCell>
                <T.TableCell>{formatPrice(intl, row.estimatedPrice)}</T.TableCell>
                <T.TableCell>{`${formatNullNumber(row.obsolescence)}${
                  row.obsolescence || row.obsolescence === 0 ? " %" : ""
                }`}</T.TableCell>
                <T.TableCell>{formatPrice(intl, row.totalPrice)}</T.TableCell>
              </T.TableRow>
            ))}
          {Number.isFinite(restitution.totalDamages) && (
            <TableRow sx={{ backgroundColor: COLORS.GREY_BG }}>
              <T.TableCell ffamily={fonts.bold}>Total HT</T.TableCell>
              <T.TableCell />
              <T.TableCell />
              <T.TableCell />
              <T.TableCell />
              <T.TableCell />
              <T.TableCell />
              <T.TableCell />
              <T.TableCell ffamily={fonts.bold}>
                {formatPrice(
                  intl,
                  restitution.damages?.reduce((prev, cur) => prev + (cur.estimatedPrice || 0), 0)
                )}
              </T.TableCell>
              <T.TableCell />
              <T.TableCell ffamily={fonts.bold}>{formatPrice(intl, restitution.totalDamages)}</T.TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default DamagesTable
