import * as React from "react"

import { COLORS } from "assets/styles/colors"
import { Damage } from "types/common.types"
import { DamageZones } from "types/enums.types"

interface Props {
  damages: Array<Damage>
  className?: string
}

/*
 * can't nest elements to use `onClick` https://github.com/react-native-svg/react-native-svg/issues/1473
 * */
/*
 * `xmlns` typescript error https://github.com/react-native-svg/react-native-svg/issues/1638
 * */
const ExpertiseVU: React.FC<Props> = ({ damages, ...rest }) => {
  const DamagedPositions = damages.map((damage) => damage.position)
  const colorZone = (zone: DamageZones) => (DamagedPositions.includes(zone) ? COLORS.PRIMARY : COLORS.WHITE)

  return (
    /* eslint-disable react/jsx-props-no-spreading */
    <svg viewBox="0 0 1188.62 1194.14" {...rest} stroke={COLORS.BLACK} strokeWidth={4} fill="white">
      {/*------------------------- Gauche --------------------------------------*/}
      <g id="Calque_47" data-name="Calque 47" fill={colorZone(DamageZones.LEFT_REAR_FENDER)}>
        <path
          id={"Carrosserie-G"}
          d="M1042.1,252c0,4.43-90.51,107.44-140.45,172.17-3.33,4.31-27.21,37.4-32.17,39.64-5.42,2.45-89.8,2.5-95.75,2.5H634.05l-127.35,0H443c-9.8,0-92.16-1.57-93.38-2.61-3.93-3.35-3.58-8.64-3.58-17.81q0-127.63,0-255.27c0-10.2-2.23-23.29,0-33.2,2.51-11.12,39.41-7.43,58.72-7.36C405,187.61,433.09,218,470.67,218c37.41,0,68.18-29.37,68.59-66.68,32,.11,141.6-.53,173.55-.42l145.77.51c-.11,1.59.34,2.83.34,4.45,0,37.67,31.44,68.61,69.11,68.61s71.15-30.94,71.15-68.61c0-1.45.09-3.11,0-4.54,28.57.1,43.36,3.55,42.89,20.67C1041.52,192.42,1042.1,247.56,1042.1,252Z"
          transform="translate(-24.74 -95.84)"
        />
      </g>
      <g id="ByMe-CarosserieGauche-AV" fill={colorZone(DamageZones.LEFT_FRONT_FENDER)}>
        <path
          id="CarrosserieDroit-AV"
          d="M 1042.1 252 C 1042.1 256.43 951.59 359.44 901.65 424.17 C 898.32 428.48 874.44 461.57 869.48 463.81 C 864.06 466.26 779.68 466.31 773.73 466.31 H 745 V 151 L 858.58 151.41 C 858.47 153 858.92 154.24 858.92 155.86 C 858.92 193.53 890.36 224.47 928.03 224.47 S 999.18 193.53 999.18 155.86 C 999.18 154.41 999.27 152.75 999.18 151.32 C 1027.75 151.42 1042.54 154.87 1042.07 171.99 C 1041.52 192.42 1042.1 247.56 1042.1 252 Z"
          transform="translate(-24.74 -95.84)"
        />
        <g id="Calque_43" data-name="DoorLeftFront 43" fill={colorZone(DamageZones.LEFT_FRONT_DOOR)}>
          <path
            id={"Portiere-G"}
            d="M760.46,442V167.56h81.19c-.12,0,12.27,28.59,13.45,30.47,12.37,19.79,31,35.08,53.94,40.58,5.9,1.42,14.48,1.82,18.35,2.37s3.28,15.47,3.3,10.42q0,12.75,0,25.5,0,29.42.09,58.82c0,8.81.35,14.4-5.13,21.65-6.38,8.45-12.87,16.81-19.31,25.21L867.72,433c-4.2,5.5-6.31,8.7-13.52,9.08-4.2.22-8.49,0-12.7,0h-81Z"
            transform="translate(-24.74 -95.84)"
          />
          <rect id="Poignee" x="748.97" y="212.56" width="31.66" height="12.13" rx="6.06" />
          <g id="Calque_42" data-name="WindowLeftFront 42" fill={colorZone(DamageZones.LEFT_FRONT_WINDOW)}>
            <path
              id={"Fenetre-G"}
              d="M925.63,357.37c-6.38,8.45-12.87,16.81-19.31,25.21L867.72,433c-4.2,5.5-6.31,8.7-13.52,9.08-4.2.22-8.49,0-12.7,0h-81V335.72h170.3C930.78,344.53,931.11,350.12,925.63,357.37Z"
              transform="translate(-24.74 -95.84)"
            />
          </g>
        </g>
      </g>
      <g id="Calque_41" data-name="Calque 41">
        <ellipse id="Clignottant-G" cx="939.14" cy="183.17" rx="13.02" ry="9.45" />
      </g>
      <g id="Calque_45" data-name="RoueAV-L 45" fill={colorZone(DamageZones.LEFT_FRONT_WHEEL)}>
        <circle id={"RoueAV-G"} cx="904.22" cy="60.01" r="52.47" />
      </g>
      <g id="Calque_46" data-name="Calque 46" fill={colorZone(DamageZones.LEFT_REAR_WHEEL)}>
        <circle id={"RoueAR-G"} cx="446.72" cy="53.47" r="52.47" />
      </g>

      <g id="Calque_48" data-name="Calque 48" fill={colorZone(DamageZones.LEFT_SIDE_SILL)}>
        <rect id="BasDeCaisse-G" x="513.64" y="18.29" width="320.88" height="25.96" />
      </g>

      {/*------------------------------------------------ Coté droit ---------------------------------------*/}
      <g id="Calque_17" data-name="Calque 17" fill={colorZone(DamageZones.RIGHT_REAR_FENDER)}>
        <path
          id="Carrosserie-D"
          d="M1041.58,1133.83c0-4.43-90.51-107.43-140.45-172.17-3.33-4.31-27.21-37.4-32.17-39.64-5.42-2.44-89.8-2.5-95.75-2.5H442.51c-9.8,0-92.16,1.57-93.38,2.62-3.93,3.34-3.58,8.64-3.58,17.8q0,127.64,0,255.27c0,10.2-2.23,23.29,0,33.21,2.51,11.11,39.41,7.42,58.72,7.36.16-37.54,28.3-67.93,65.88-67.93,37.41,0,68.18,29.37,68.59,66.69,32-.12,141.6.53,173.55.41l145.77-.51c-.11-1.59.34-2.83.34-4.45,0-37.66,31.44-68.61,69.11-68.61s71.15,31,71.15,68.61c0,1.46.09,3.12,0,4.55,28.57-.1,43.36-3.56,42.89-20.68C1041,1193.4,1041.58,1138.26,1041.58,1133.83Z"
          transform="translate(-24.74 -95.84)"
        />
      </g>
      <g id="ByMe" data-name={"CarrosserieDroit-AV"} fill={colorZone(DamageZones.RIGHT_FRONT_FENDER)}>
        <path
          id="CarrosserieDroit-AV"
          d="M 1041.58 1133.83 C 1041.58 1129.4 951.07 1026.4 901.13 961.66 C 897.8 957.35 873.92 924.26 868.96 922.02 C 863.54 919.58 779.16 919.52 773.21 919.52 H 748 V 1235 L 858.06 1234.44 C 857.95 1232.85 858.4 1231.61 858.4 1229.99 C 858.4 1192.33 889.84 1161.38 927.51 1161.38 S 998.66 1192.38 998.66 1229.99 C 998.66 1231.45 998.75 1233.11 998.66 1234.54 C 1027.23 1234.44 1042.02 1230.98 1041.55 1213.86 C 1041 1193.4 1041.58 1138.26 1041.58 1133.83 Z"
          transform="translate(-24.74 -95.84)"
        />
        <g id="Calque_13" data-name="Calque 13" fill={colorZone(DamageZones.RIGHT_FRONT_DOOR)}>
          <path
            id="PortiereAV-D"
            d="M759.94,943.79v274.47h81.19c-.12,0,12.27-28.59,13.45-30.48,12.37-19.78,31-35.08,53.94-40.58,5.9-1.41,14.48-1.81,18.35-2.37s3.28-15.46,3.3-10.41q0-12.75,0-25.51,0-29.4.09-58.81c0-8.82.35-14.41-5.13-21.66-6.38-8.44-12.87-16.81-19.31-25.21l-38.6-50.4c-4.2-5.49-6.32-8.7-13.52-9.07-4.2-.22-8.49,0-12.7,0h-81Z"
            transform="translate(-24.74 -95.84)"
          />
          <rect id="Poignee-2" data-name="Poignee" x="748.45" y="969.45" width="31.66" height="12.13" rx="6.06" />
          <g id="Calque_18" data-name="Calque 18" fill={colorZone(DamageZones.FRONT_RIGHT_WINDOW)}>
            <path
              id="FenetreAV-D"
              d="M925.11,1028.44c-6.38-8.44-12.87-16.81-19.31-25.21l-38.6-50.4c-4.2-5.49-6.32-8.7-13.52-9.07-4.2-.22-8.49,0-12.7,0h-81V1050.1h170.3C930.26,1041.28,930.59,1035.69,925.11,1028.44Z"
              transform="translate(-24.74 -95.84)"
            />
          </g>
        </g>
      </g>
      <g id="Calque_9" data-name="Calque 9">
        <ellipse id="Clignottant-D" data-name="Clignottant-D" cx="938.62" cy="1010.97" rx="13.02" ry="9.45" />
      </g>

      <g id="_33" data-name="33" fill={colorZone(DamageZones.RIGHT_REAR_DOOR)}>
        <rect id="Portiere-D" x="576.64" y="847.95" width="136.85" height="290.72" />
        <rect id="Poignee-3" data-name="Poignee" x="589.92" y="969.45" width="31.66" height="12.13" rx="6.06" />
      </g>
      <g id="Calque_7" data-name="Calque 7" fill={colorZone(DamageZones.RIGHT_ROCKER_PANEL)}>
        <rect id="BasDeCaisse-G" data-name="BasDeCaisse-G" x="513.12" y="1149.88" width="320.88" height="25.96" />
      </g>

      {/*------------- Roue Droit ----------------------*/}
      <g id="Calque_16" data-name="Calque 16" fill={colorZone(DamageZones.RIGHT_FRONT_WHEEL)}>
        <circle id="RoueAV-R" cx="903.7" cy="1134.12" r="52.47" />
      </g>
      <g id="Calque_15" data-name="Calque 15" fill={colorZone(DamageZones.RIGHT_REAR_WHEEL)}>
        <circle id="RoueAR-R" cx="446.2" cy="1140.67" r="52.47" />
      </g>
      <g id="Calque_40" data-name="Calque 40" fill={colorZone(DamageZones.SPARE_WHEEL)}>
        <circle id={"Secours"} cx="237.09" cy="1016.97" r="49.79" />
      </g>

      {/*------------------- Avant ---------------------------*/}
      <g id="Calque_12" data-name="Calque 12" fill={colorZone(DamageZones.HOOD)}>
        <path
          d="M1022.06,692.34s1.59,94.72,0,97.27c.58,42.9-30.18,52.09-30.18,52.09s-114.47,14-120,13.66c10.12-30.42,9.44-162.25,9.44-162.25s-.34-146.47-9.42-161c.26,0,119.93,11.65,119.93,11.65s30.76,9.19,30.18,52.08c1.6,2.56,0,97.28,0,97.28"
          transform="translate(-24.74 -95.84)"
        />
        <line x1="872.23" y1="496.12" x2="998.01" y2="532.77" />
        <line x1="872.23" y1="697.65" x2="998.01" y2="661.08" />
      </g>
      <g id="Calque_11" data-name="Calque 11" fill={colorZone(DamageZones.RIGHT_REAR_VIEW_MIRROR)}>
        <ellipse id="Retro-D" cx="838.7" cy="793.81" rx="11.49" ry="23.35" />
      </g>
      <g id="Calque_10" data-name="Calque 10" fill={colorZone(DamageZones.LEFT_REAR_VIEW_MIRROR)}>
        <ellipse cx="838.7" cy="400.5" rx="11.49" ry="23.35" />
      </g>
      <g id="Calque_8" data-name="Calque 8" fill={colorZone(DamageZones.ROOF)}>
        <path
          d="M881.36,693.11c0-19.66-3.63-144.13-9.17-158.16-4.61-11.67-37-11.5-37-11.5H355.91V862H835.22s29.52,3.51,35.23-7.36C878.94,838.51,881.36,712.77,881.36,693.11Z"
          transform="translate(-24.74 -95.84)"
        />
      </g>
      <g id="Calque_14" data-name="Calque 14" fill={colorZone(DamageZones.FRONT_WINDSHIELD)}>
        <path
          d="M763.15,693.34c0-131.93-19-127.24-19-130.13s97.18-26.57,102-24.75c5.09,2.9,11,9.76,15.83,154.21v.67c-4.65,133.17-13.42,152.75-15.83,154.21-2.69,1.64-97.49-24-102-24.75s19,1.8,19-130.13"
          transform="translate(-24.74 -95.84)"
        />
      </g>
      {/*Roue vision centre*/}
      <g id="Calque_28" data-name="Calque 28">
        <path d="M871.94,532.1s.12-12.27,55.86-7.51,62.43,18.8,62.43,18.8L871.94,532.1" transform="translate(-24.74 -95.84)" />
      </g>
      <g id="Calque_29" data-name="Calque 29">
        <path d="M411.93,523.07s-1-12.46,57.26-13,66.57,13.09,66.57,13.09l-123.83-.13" transform="translate(-24.74 -95.84)" />
      </g>
      <g id="Calque_30" data-name="Calque 30">
        <path d="M871.92,855.36s.26,12.5,58.29,6.92,64.85-19.95,64.85-19.95l-123.14,13" transform="translate(-24.74 -95.84)" />
      </g>
      <g id="Calque_31" data-name="Calque 31">
        <path d="M415.91,863s-1,12.46,57.27,12.91,66.56-13.15,66.56-13.15L415.91,863" transform="translate(-24.74 -95.84)" />
      </g>

      {/*----------------------- Arriere ----------------------------*/}
      <g id="Calque_44" data-name="Calque 44" fill={colorZone(DamageZones.TRUNK)}>
        <rect x="89.41" y="427.36" width="220.6" height="339.33" rx="12" />
        <g id="Calque_19" data-name="Coffre-D" fill={colorZone(DamageZones.TRUNK)}>
          <rect x="89.41" y="611.28" width="220.6" height="108" rx="12" />
          <rect x="184.11" y="620.49" width="114.89" height="82" rx="12" />
        </g>
        <g id="Calque_20" data-name="Coffre-G" fill={colorZone(DamageZones.TRUNK)}>
          <rect x="89.41" y="476.01" width="220.6" height="108" rx="12" />
          <rect x="184.11" y="486.46" width="114.89" height="82" rx="12" />
          <rect
            id="Poignee-4"
            data-name="Poignee"
            x="180.67"
            y="649.36"
            width="19.64"
            height="12.13"
            rx="6.06"
            transform="translate(821.17 369.1) rotate(90)"
          />
        </g>
        <g id="Calque_21" data-name="Calque 21" fill={colorZone(DamageZones.REAR_LICENSE_PLATE)}>
          <rect x="103.48" y="493.07" width="20.88" height="67.58" />
        </g>
        {/*Phare */}
        <g id="Calque_24" data-name="Calque 24">
          <rect x="89.41" y="427.36" width="220.6" height="35.05" rx="12" />
        </g>
        <g id="Calque_25" data-name="Calque 25" fill={colorZone(DamageZones.LEFT_REAR_HEADLIGHT)}>
          <rect x="89.41" y="427.21" width="48.7" height="35.2" rx="12" />
        </g>
        <g id="Calque_26" data-name="Calque 26">
          <rect x="89.41" y="731.22" width="220.6" height="35.3" rx="12" />
        </g>
        <g id="Calque_27" data-name="Calque 27" fill={colorZone(DamageZones.RIGHT_REAR_HEADLIGHT)}>
          <rect x="89.41" y="731.22" width="48.7" height="35.3" rx="12" />
        </g>
      </g>

      <g>
        <g id="Calque_22" data-name="Calque 22" fill={colorZone(DamageZones.RIGHT_REAR_BUMPER)}>
          <path
            d="M27.46,693.16H68.31c0,142.22,40.49,157.85,27.58,161.62l-33.19,9.7C27.46,825.65,22.26,717.41,27.46,693.16Z"
            transform="translate(-24.74 -95.84)"
          />
        </g>
        <g id="Calque_23" data-name="Calque 23" fill={colorZone(DamageZones.LEFT_REAR_BUMPER)}>
          <path
            d="M27.46,693.16H68.31c0-142.21,40.49-157.84,27.58-161.61L62.7,521.84C27.46,560.68,22.26,668.92,27.46,693.16Z"
            transform="translate(-24.74 -95.84)"
          />
        </g>
      </g>

      {/* ----------------------------- Avant -----------------------------------*/}
      <g id="Calque_32" data-name="Calque 32">
        <path
          d="M1075.82,698.4l56.43-.09c-.81,145.83-34,154.85-34,154.85-27.74,5.39-65.87-10.47-64.26-11,37.88-30.64,41.8-144.26,41.8-144.26Z"
          transform="translate(-24.74 -95.84)"
        />
        <path
          d="M1075.91,698.51l56.43.09c-.82-145.83-34-154.86-34-154.86-27.74-5.38-65.87,10.48-64.27,11C1072,585.37,1075.91,699,1075.91,699Z"
          transform="translate(-24.74 -95.84)"
        />

        <g id="Calque_39" data-name="Calque 39" fill={colorZone(DamageZones.LEFT_FRONT_HEADLIGHT)}>
          <path
            d="M1047.29,570.56l60-18.69s16.34,14.71,23.3,97.31c0,.38-60.81-7.65-60.81-7.65S1065.74,604.88,1047.29,570.56Z"
            transform="translate(-24.74 -95.84)"
          />
          <circle cx="1069.56" cy="486.88" r="15.66" />
          <circle cx="1075.25" cy="526.85" r="15.66" />
        </g>
        <g id="Calque_37" data-name="Calque 37">
          <path
            fill={colorZone(DamageZones.GRILL)}
            d="M1075.91,697.16s-3.15,41.36-4.38,55.51l59-6.17s1.88-38.13,1.76-49.2v1.07c.12-11.07-1.76-49.19-1.76-49.19l-60.81-7.65c3.59,15.15,6.14,57,6.14,57"
            transform="translate(-24.74 -95.84)"
          />
          <line x1="1061.72" y1="547.25" x2="1061.72" y2="547.25" />
          <line x1="1061.72" y1="656.36" x2="1061.72" y2="656.36" />
          <line x1="1063.72" y1="547.25" x2="1063.72" y2="654.54" />
          <line x1="1077.02" y1="549.46" x2="1077.81" y2="654.56" />
          <line x1="1089.91" y1="551.26" x2="1089.91" y2="652.74" />
          <line x1="1098.32" y1="552.45" x2="1098.6" y2="651.66" />
        </g>
        <g id="Calque_38" data-name="Calque 38" fill={colorZone(DamageZones.FRONT_RIGHT_HEADLIGHT)}>
          <path
            d="M1048.31,824.48l58.24,20.46s16.65-8.51,24-98.44c-3.18.33-60.37,5.7-60.37,5.7S1066.09,790.6,1048.31,824.48Z"
            transform="translate(-24.74 -95.84)"
          />
          <circle cx="1069.56" cy="716.73" r="15.66" />
          <circle cx="1075.25" cy="676.77" r="15.66" />
        </g>
      </g>

      <g id="Calque_36" data-name="Parechoc-AV" fill={colorZone(DamageZones.FRONT_BUMPER)}>
        <path
          d="M1177.13,527.91l-33.19,9.71s27.57,29.61,27.57,161.61-27.57,160.6-27.57,160.6L1177.13,870s35.24,8.17,35.24-170.81S1177.13,527.91,1177.13,527.91Z"
          transform="translate(-24.74 -95.84)"
        />
        <line x1="554.77" y1="595.12" x2="637.15" y2="595.12" />
        <rect x="637.15" y="586.34" width="19.57" height="19.57" />
        <g id="Calque_33" data-name="Calque 33" fill={colorZone(DamageZones.RIGHT_FRONT_BUMPER)}>
          <ellipse cx="1156.37" cy="731.54" rx="7.91" ry="14.81" />
        </g>
        <g id="Calque_34" data-name="Calque 34">
          <rect x="1159.04" y="553.35" width="17.7" height="99.39" fill={colorZone(DamageZones.FRONT_BUMPER)} />
        </g>
        <g id="Calque_35" data-name="Calque 35" fill={colorZone(DamageZones.LEFT_FRONT_BUMPER)}>
          <ellipse cx="1156.37" cy="474.72" rx="7.91" ry="14.81" />
        </g>
      </g>
    </svg>
  )
}

export default ExpertiseVU
