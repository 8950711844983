import React, { FormEvent, useState } from "react"

import { CheckCircle, Visibility, VisibilityOff } from "@mui/icons-material"
import { Box, Button, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Paper } from "@mui/material"
import { AxiosError } from "axios"
import { useSnackbar } from "notistack"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import { FormattedMessage, useIntl } from "react-intl"
import { useSelector } from "react-redux"
import { useSearchParams } from "react-router-dom"

import api from "api/api"
import { AbsoluteLoader } from "components/loader"
import Logo from "components/logo"
import { Title } from "components/typography/Title"
import { RootState } from "store"
import { RECAPTCHA_ACTIONS } from "types/enums.types"
import { getErrorMessages } from "utils/error.utils"

import * as S from "./Password.styles"

const REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[€@$!%*?&])[A-Za-z\d€@$!%*?&]{8,}$/

type Props = {
  // true if reset password page
  reset: boolean
}

const Validation = ({ reset }: Props) => {
  const [loading, setLoading] = useState(false)
  const [done, setDone] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false)
  const [password, setPassword] = useState("")
  const [repeatPassword, setRepeatPassword] = useState("")
  const intl = useIntl()

  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated)

  const { executeRecaptcha } = useGoogleReCaptcha()
  const [searchParams] = useSearchParams()
  const key = searchParams.get("key") || ""

  const recaptchaAction = reset ? RECAPTCHA_ACTIONS.RESET_PASSWORD : RECAPTCHA_ACTIONS.ACTIVATE
  const title = `screen.password.${reset ? "reset" : "validation"}.title`
  const apiCall = reset ? api.authentication.resetPassword : api.authentication.activate

  const { enqueueSnackbar } = useSnackbar()

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleClickShowPasswordConfirmation = () => {
    setShowPasswordConfirmation(!showPasswordConfirmation)
  }

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!REGEX.test(password)) {
      enqueueSnackbar(intl.formatMessage({ id: "error.password_not_match_rule" }), { variant: "error" })
      return
    }

    if (password !== repeatPassword) {
      enqueueSnackbar(intl.formatMessage({ id: "error.password_not_match" }), { variant: "error" })
      return
    }

    if (!executeRecaptcha) {
      enqueueSnackbar(intl.formatMessage({ id: "error.recaptcha" }), { variant: "error" })
      return
    }

    try {
      const recaptchaToken = await executeRecaptcha(recaptchaAction)
      await apiCall({ key, recaptchaToken, password, repeatPassword })

      setDone(true)
    } catch (e: any) {
      const messages = getErrorMessages(e as AxiosError)
      messages.forEach((m) => enqueueSnackbar(intl.formatMessage({ id: m }), { variant: "error" }))
    } finally {
      setLoading(false)
    }
  }

  const renderIcon = (show: boolean, onClick: () => void) => (
    <InputAdornment position="end">
      <IconButton aria-label="toggle password visibility" onClick={onClick} onMouseDown={handleMouseDownPassword} edge="end">
        {show ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    </InputAdornment>
  )

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      {loading && <AbsoluteLoader />}
      {!isAuthenticated && (
        <Box maxWidth={1440} p={2} width="100%">
          <Box display="flex" alignItems="center">
            <Logo />
          </Box>
        </Box>
      )}
      <Paper sx={{ p: 2, maxWidth: 600, m: 2 }} elevation={2}>
        <Box display="flex" justifyContent="center" sx={{ pb: 2 }}>
          <Title>
            <FormattedMessage id={title} />
          </Title>
        </Box>
        <Box display="flex" flexDirection="column">
          {done && (
            <Box display="flex" flexDirection="row" pt={2} pb={4}>
              <Box p="0 4px" display="flex" alignItems="center">
                <CheckCircle fontSize={"large"} color="success" />
              </Box>
              <Title>
                <FormattedMessage id="screen.password.success" />
              </Title>
            </Box>
          )}
          {!done && (
            <>
              <span>
                <FormattedMessage id="screen.password.rule" />
                <b>{` ${intl.formatMessage({ id: "screen.password.rule.bold" })}`}</b>
              </span>
              <Box display="flex" flexDirection="column" p={2}>
                <form onSubmit={handleSubmit}>
                  <FormControl fullWidth variant="outlined" sx={{ m: 1 }}>
                    <InputLabel htmlFor="outlined-adornment-password">
                      {intl.formatMessage({ id: "screen.password.input.password.label" })}
                    </InputLabel>
                    <OutlinedInput
                      fullWidth
                      id="outlined-adornment-password"
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onChange={(event) => setPassword(event.target.value)}
                      endAdornment={renderIcon(showPassword, handleClickShowPassword)}
                      label={intl.formatMessage({ id: "screen.password.input.password.label" })}
                    />
                  </FormControl>
                  <FormControl fullWidth variant="outlined" sx={{ m: 1 }}>
                    <InputLabel htmlFor="outlined-adornment-password">
                      {intl.formatMessage({ id: "screen.password.input.confirmation.label" })}
                    </InputLabel>
                    <OutlinedInput
                      fullWidth
                      id="outlined-adornment-password"
                      type={showPasswordConfirmation ? "text" : "password"}
                      value={repeatPassword}
                      onChange={(event) => setRepeatPassword(event.target.value)}
                      endAdornment={renderIcon(showPasswordConfirmation, handleClickShowPasswordConfirmation)}
                      label={intl.formatMessage({ id: "screen.password.input.confirmation.label" })}
                    />
                  </FormControl>
                  <Box display="flex" justifyContent="center">
                    <Button type="submit" variant="contained">
                      {intl.formatMessage({ id: "button.validate" })}
                    </Button>
                  </Box>
                </form>
              </Box>
            </>
          )}
          <S.Recaptcha>
            <FormattedMessage
              id="recaptcha"
              values={{
                privacy: (
                  <S.RecaptchaLink href="https://policies.google.com/privacy">
                    {intl.formatMessage({
                      id: "recaptcha.privacy",
                    })}
                  </S.RecaptchaLink>
                ),
                terms: (
                  <S.RecaptchaLink href="https://policies.google.com/terms">
                    {intl.formatMessage({
                      id: "recaptcha.terms",
                    })}
                  </S.RecaptchaLink>
                ),
              }}
            />
          </S.Recaptcha>
        </Box>
      </Paper>
    </Box>
  )
}

export default Validation
