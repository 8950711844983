import jwt_decode from "jwt-decode"

import { getConfiguration } from "config/env"
import { CONFIG } from "types/enums.types"

import { AUTH_MODE, AUTH_TYPE, LOCAL_STORAGE } from "./authentication-constants.utils"
// PKCE HELPER FUNCTIONS

declare global {
  interface Window {
    __UPDATING_AUTHENTICATION__: boolean
  }
}

// Generate a secure random string using the browser crypto functions
export function generateRandomString() {
  const array = new Uint32Array(28)
  window.crypto.getRandomValues(array)
  return Array.from(array, (dec) => ("0" + dec.toString(16)).substr(-2)).join("")
}

// Calculate the SHA256 hash of the input text.
// Returns a promise that resolves to an ArrayBuffer
export function sha256(plain: string) {
  const encoder = new TextEncoder()
  const data = encoder.encode(plain)
  return window.crypto.subtle.digest("SHA-256", data)
}

// Base64-urlencodes the input string
export function base64urlencode(str: string) {
  // Convert the ArrayBuffer to string using Uint8 array to convert to what btoa accepts.
  // btoa accepts chars only within ascii 0-255 and base64 encodes them.
  // Then convert the base64 encoded to base64url encoded
  //   (replace + with -, replace / with _, trim trailing =)
  return btoa(String.fromCharCode.apply(null, new Uint8Array(str as never) as never))
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, "")
}

// Return the base64-urlencoded sha256 hash for the PKCE challenge
export async function pkceChallengeFromVerifier(v: string) {
  const hashed = await sha256(v)
  return base64urlencode(hashed as never)
}

export function inIframe() {
  try {
    return window.self !== window.top
  } catch (e) {
    return true
  }
}

/* Return current token
 *  if expired, renew token
 * if > 4h redirect to login
 *  */

/* called before every call. If 401 > invalid token, explicit reconnect */
export async function getTokenSilently(): Promise<any> {
  const jwt = localStorage.getItem(LOCAL_STORAGE.API_TOKEN)
  const authMode = localStorage.getItem(LOCAL_STORAGE.AUTH_MODE) as AUTH_MODE

  if (getConfiguration(CONFIG.ENV) === "DEV" && authMode !== AUTH_MODE.OKTA) return jwt

  if (isTokenxpired(authMode, jwt)) {
    updateToken()
  }

  return jwt
}

export const isTokenxpired = (authMode: AUTH_MODE, token?: string | null): boolean => {
  if (!token) {
    return true
  }

  const { exp } = jwt_decode(token) as any

  const dateThreshold = authMode === AUTH_MODE.OKTA ? parseInt(exp, 10) * 1000 : parseInt(exp, 10) * 1000 - 60 * 10 * 1000

  return dateThreshold < new Date().getTime()
}

export const updateToken = async () => {
  const authMode = localStorage.getItem(LOCAL_STORAGE.AUTH_MODE) as AUTH_MODE
  const authType = localStorage.getItem(LOCAL_STORAGE.AUTH_TYPE) as AUTH_TYPE | undefined

  if (!window.__UPDATING_AUTHENTICATION__ && authMode !== AUTH_MODE.RESTIT) {
    window.__UPDATING_AUTHENTICATION__ = true
    console.debug("JWT TOKEN Renewal", authMode)

    refreshAccessToken(
      (request, body) => {
        const access_token = authMode === AUTH_MODE.IDP ? body.access_token : body.id_token
        localStorage.setItem(LOCAL_STORAGE.API_TOKEN, access_token)
        window.__UPDATING_AUTHENTICATION__ = false
      },
      (request, error) => {
        console.error("Error while renewing JWT Token", error.error)
        window.__UPDATING_AUTHENTICATION__ = true
      },
      authMode,
      authType
    )

    console.debug("JWT TOKEN Renewal Success", authMode)
  }
}

const getAccessTokenRequest = (
  params: any,
  authMode: AUTH_MODE,
  success: (request: XMLHttpRequest, body: any) => void,
  error: (request: XMLHttpRequest, error: any) => void
) => {
  const tokenEndpoint = authMode === AUTH_MODE.OKTA ? getConfiguration(CONFIG.TOKEN_ENDPOINT_OKTA) : getConfiguration(CONFIG.TOKEN_ENDPOINT)

  const request = new XMLHttpRequest()
  request.open("POST", tokenEndpoint, true)
  request.setRequestHeader("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8")

  request.onload = function () {
    let body = {}
    try {
      body = JSON.parse(request.response)
    } catch (e) {
      console.error("error", e)
    }

    if (request.status === 200) {
      success(request, body)
    } else {
      error(request, body)
    }
  }

  request.onerror = function () {
    error(request, {})
  }

  const body = Object.keys(params)
    .map((key) => (params[key as keyof typeof params] ? key + "=" + params[key as keyof typeof params] : ""))
    .join("&")

  request.send(body)
}

const refreshAccessToken = (
  success: (request: XMLHttpRequest, body: any) => void,
  error: (request: XMLHttpRequest, error: any) => void,
  authMode: AUTH_MODE,
  authType?: AUTH_TYPE
) => {
  const params = {
    grant_type: "refresh_token",
    refresh_token: localStorage.getItem(LOCAL_STORAGE.REFRESH_TOKEN),
    ...(authMode === AUTH_MODE.IDP && {
      redirect_uri: getConfiguration(CONFIG.REDIRECT_URI),
      ...(authType === AUTH_TYPE.ARCA && {
        client_id: encodeURIComponent(getConfiguration(CONFIG.CLIENT_ID_ARCA)),
        scope: encodeURIComponent(getConfiguration(CONFIG.REQUESTED_SCOPES_ARCA)),
      }),
      ...(authType === AUTH_TYPE.RNET && {
        client_id: encodeURIComponent(getConfiguration(CONFIG.CLIENT_ID_RNET)),
        scope: encodeURIComponent(getConfiguration(CONFIG.REQUESTED_SCOPES_RNET)),
      }),
    }),
    ...(authMode === AUTH_MODE.OKTA && {
      client_id: encodeURIComponent(getConfiguration(CONFIG.CLIENT_ID_OKTA)),
      redirect_uri: getConfiguration(CONFIG.REDIRECT_URI_OKTA),
      scope: encodeURIComponent(CONFIG.REQUESTED_SCOPES_OKTA),
    }),
  }

  getAccessTokenRequest(params, authMode, success, error)
}

export function getAccessToken(
  code: any,
  success: (request: XMLHttpRequest, body: any) => void,
  error: (request: XMLHttpRequest, error: any) => void,
  authMode: AUTH_MODE,
  authType?: AUTH_TYPE
) {
  const params = {
    code,
    grant_type: "authorization_code",
    code_verifier: localStorage.getItem(LOCAL_STORAGE.PKCE_VERIFIER),
    ...(authMode === AUTH_MODE.IDP && {
      redirect_uri: getConfiguration(CONFIG.REDIRECT_URI),
      ...(authType === AUTH_TYPE.ARCA && {
        client_id: encodeURIComponent(getConfiguration(CONFIG.CLIENT_ID_ARCA)),
      }),
      ...(authType === AUTH_TYPE.RNET && {
        client_id: encodeURIComponent(getConfiguration(CONFIG.CLIENT_ID_RNET)),
      }),
    }),
    ...(authMode === AUTH_MODE.OKTA && {
      client_id: encodeURIComponent(getConfiguration(CONFIG.CLIENT_ID_OKTA)),
      redirect_uri: getConfiguration(CONFIG.REDIRECT_URI_OKTA),
    }),
  }

  getAccessTokenRequest(params, authMode, success, error)
}

export async function generateLoginUrl(silent = false, authMode: AUTH_MODE, authType?: AUTH_TYPE) {
  const state = generateRandomString()
  localStorage.setItem(LOCAL_STORAGE.PKCE, state)

  // Create and store a new PKCE code_verifier (the plaintext random secret)
  const code_verifier = generateRandomString()
  localStorage.setItem(LOCAL_STORAGE.PKCE_VERIFIER, code_verifier)

  // Hash and base64-urlencode the secret to use as the challenge
  const code_challenge = await pkceChallengeFromVerifier(code_verifier)

  const authorizationEndpoint =
    authMode === AUTH_MODE.IDP ? getConfiguration(CONFIG.AUTHORIZATION_ENDPOINT) : getConfiguration(CONFIG.AUTHORIZATION_ENDPOINT_OKTA)

  const params = {
    response_type: "code",
    code_challenge: encodeURIComponent(code_challenge),
    code_challenge_method: "S256",
    state: encodeURIComponent(state),
    ...(authMode === AUTH_MODE.IDP && {
      redirect_uri: encodeURIComponent(getConfiguration(CONFIG.REDIRECT_URI)),
      ...(authType === AUTH_TYPE.ARCA && {
        client_id: encodeURIComponent(getConfiguration(CONFIG.CLIENT_ID_ARCA)),
        scope: encodeURIComponent(getConfiguration(CONFIG.REQUESTED_SCOPES_ARCA)),
        acr_values: getConfiguration(CONFIG.ACR_VALUES_ARCA),
      }),
      ...(authType === AUTH_TYPE.RNET && {
        client_id: encodeURIComponent(getConfiguration(CONFIG.CLIENT_ID_RNET)),
        scope: encodeURIComponent(getConfiguration(CONFIG.REQUESTED_SCOPES_RNET)),
        acr_values: getConfiguration(CONFIG.ACR_VALUES_RNET),
      }),
    }),
    ...(authMode === AUTH_MODE.OKTA && {
      client_id: encodeURIComponent(getConfiguration(CONFIG.CLIENT_ID_OKTA)),
      scope: encodeURIComponent(getConfiguration(CONFIG.REQUESTED_SCOPES_OKTA)),
      redirect_uri: encodeURIComponent(getConfiguration(CONFIG.REDIRECT_URI_OKTA)),
    }),
    ...(silent && {
      prompt: "none",
      response_mode: "web_message",
    }),
  }

  // property prompt is missing in object literal :(
  const parameters = Object.keys(params)

    .map((key) => (params[key as keyof typeof params] ? key + "=" + params[key as keyof typeof params] : ""))
    .join("&")

  // Build the authorization URL
  return authorizationEndpoint + "?" + parameters
}
