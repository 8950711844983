import React from "react"

import styled from "styled-components"

import { useReduxDispatch } from "store"
import actions from "store/actions"
import { TermFilter } from "types/common.types"

import VirtualizedTermList from "./VirtualizedTermList"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  max-height: 300px;
  overflow-y: auto;
`

type Props = {
  filter: TermFilter
  name: string
}

const TermFilterComponent = ({ filter, name }: Props) => {
  const dispatch = useReduxDispatch()

  if (!filter || !filter.terms) return <div />

  return (
    <Container>
      {filter.terms && (
        <VirtualizedTermList
          filter={filter}
          name={name}
          onClick={(term) => {
            dispatch(actions.search.checkTerm({ filter: name, name: term }))
          }}
        />
      )}
    </Container>
  )
}

export default TermFilterComponent
