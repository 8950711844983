import styled from "@emotion/styled"

import { fonts } from "assets/fonts/fonts"
import { COLORS } from "assets/styles/colors"

export const RecaptchaLink = styled.a`
  font-weight: bold;
  color: ${COLORS.GREY_DARK};
  text-decoration: none;
`

export const Recaptcha = styled.span`
  color: ${COLORS.GREY_DARK};
  font-size: 12px;
`

export const LogoText = styled.span`
  font-family: ${fonts.bolder};
  padding-left: 6px;
  font-size: 26px;
`
