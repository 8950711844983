import React from "react"

import logo from "assets/images/logo-desktop.png"

import * as S from "./Logo.styles"

const Logo = () => (
  <S.Container>
    <S.ImgLogo src={logo} />
  </S.Container>
)

export default Logo
