import React, { useEffect } from "react"

import { Checkbox } from "@mui/material"
import { useIntl } from "react-intl"
import { VariableSizeList as List } from "react-window"
import styled from "styled-components"

import { Term as TypeTerm, TermFilter, TermSearchFilter } from "types/common.types"

const Term = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 6px 0px 0;
  cursor: pointer;
  word-break: break-word;
`

interface Props {
  onClick: (term: string) => unknown
  filter: TermFilter | TermSearchFilter
  name: string
}

const VirtualizedTermList = ({ onClick, filter, name }: Props) => {
  const gridRef: React.Ref<List> = React.createRef()
  const intl = useIntl()
  const { needTranslate, terms } = filter

  /* base height = 38, add 7px each 26 chars */
  /* ugly but best i can do */
  const getItemSize = (index: number) => (terms[index] ? 37 + Math.ceil(terms[index].term.length / 26) * 7 : 44)

  useEffect(() => {
    gridRef && gridRef.current && gridRef.current.resetAfterIndex(0)
  }, [gridRef, terms])

  /* TODO if any multiple key, add filter object property name */
  /* For instance replace filter.true by filter.animated.true */
  const formatTerm = (term: TypeTerm) =>
    `${needTranslate ? intl.formatMessage({ id: `filter.${name}.${term.term}` }) : term.term} (${term.count})`

  /* show all terms if less than 10 chars */
  if (terms.length < 10) {
    return (
      <div>
        {terms.map((t, i) => (
          <Term
            key={`${t.term}${i}${t.checked}`}
            onClick={() => {
              onClick(t.term)
            }}
          >
            <Checkbox checked={!!t.checked} value={t.term} color="primary" />
            {formatTerm(t)}
          </Term>
        ))}
      </div>
    )
  }

  return (
    <List height={300} itemCount={terms.length} itemData={terms} itemSize={getItemSize} ref={gridRef} width={"100%"}>
      {({ index, style }) => (
        <div style={style}>
          <Term
            onClick={() => {
              onClick(terms[index].term)
            }}
          >
            <Checkbox checked={terms[index].checked} value={terms[index].term} color="primary" />
            {formatTerm(terms[index])}
          </Term>
        </div>
      )}
    </List>
  )
}

export default VirtualizedTermList
